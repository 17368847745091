
function ResponseFilter(response) {

    var serverResponseStatus = response.status;
    var serverResponseData = (typeof response.data != 'undefined')
      ? response.data
      : ((typeof response.error != 'undefined') ? response.error : null);

    if(serverResponseData.statusCode == 0 && serverResponseData.error && serverResponseData.error.errorCode == 2) {
      localStorage.clear();
        window.location.href = '/';
    }

    // if(serverResponseData.statusCode == 0 && serverResponseData.error && serverResponseData.error.errorCode == 10) {
    //   window.location.href = '/';
    // }

    return {
      serverResponseStatus,
      serverResponseData
    }
}

export {
    ResponseFilter
}
