import React, {Component} from 'react'
import {Col, Form, Row} from 'react-bootstrap'
import Sidebar from "../common/sidebar";
import {usersActions} from "../../_actions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import _ from 'lodash';
import moment from "moment-timezone";
import Loader from '../../components/common/Loader'


class Dishes extends Component {

    constructor(props) {
        super(props);


        this.state = {

            errors: '',
            success: '',
            userInfo: [],
            isEdit: false,
            farmImagePreview: '', farmImageErrorMsg: '', farmMediaErrorMsg: '', farmImage: [],
            farmLogoPreview: '', farmLogo: '', farmLogoErrorMsg: '',
            farmVideoPreview: '', farmVideo: '', farmVideoErrorMsg: '',
            userId: localStorage.getItem('userId'),
            farmName: '', farmStatus: '', shortInfo: '', infoAboutFarm: '', infoAboutFarmer: '', description: '',
            farmId: '',
            userProfile: [],
            dishedYou: [],
            youDished: [],
            farmImage1Preview: [],
            media: [],
            valiDate: true, loader: false,

            address: '',
            addressLine1: '',
            addressLine1ErrorMsg: '',
            addressLine2: '',
            state: '',
            postalCode: '',
            city: '',
            lat: 0,
            lng: 0

        }

    }

    componentDidMount() {
        this.getAllDishes()
        this.getUserDetail()

    }


    getAllDishes = () => {
        let {userId, farmLogo, farmImage, farmVideo, farmLogoPreview, farmVideoPreview, farmName, farmStatus, infoAboutFarm, infoAboutFarmer, description} = this.state;
        this.setState({errors: ''});

        let self = this
        this.setState({loader: true});
        let params = `?userId=${this.props.match.params.id}`

        let {dispatch} = this.props;
        dispatch(usersActions.getAllDishes(params)).then(data => {
            if (!_.isEmpty(data)) {
                let response = data.responseData.response
                self.setState({loader: false})

                if (data.statusCode == 1) {

                    self.setState({
                        response: response,
                        dishedYou: response.dishedYou,
                        youDished: response.youDished,

                    })
                } else {
                    self.setState({errors: data.error.responseMessage})
                }
            }

        });

    }
   getUserDetail= () => {
        let {userId, farmLogo, farmImage, farmVideo, farmLogoPreview, farmVideoPreview, farmName, farmStatus, infoAboutFarm, infoAboutFarmer, description} = this.state;
        this.setState({errors: ''});

        let self = this
        this.setState({loader: true});
        let params = `?userId=${this.props.match.params.id}`

        let {dispatch} = this.props;
        dispatch(usersActions.getUserDetail(params)).then(data => {
            if (!_.isEmpty(data)) {
                let userProfile = data.responseData.userProfile
                self.setState({loader: false})

                if (data.statusCode == 1) {

                    self.setState({
                        userProfile:  userProfile


                    })
                } else {
                    self.setState({errors: data.error.responseMessage})
                }
            }

        });

    }

    onKeyPress(e) {
        e = e || window.event;
        var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
        var charStr = String.fromCharCode(charCode);

        if (!charStr.match(/^[0-9]+$/))
            e.preventDefault();
    }

    ConvertDob = (dob) => {
        // let data =        Date(dob)
        let data = moment(dob).format("MM/DD/YYYY")
        return data
    }

    render() {
        let {
            shortInfo, userProfile, media, farmMediaErrorMsg, farmImage1Preview,
            dishedYou, youDished, farmLogoPreview, farmLogoErrorMsg, farmNameErrorMsg, infoAboutFarmErrorMsg, infoAboutFarmerErrorMsg, addressLine1ErrorMsg
            , farmName, infoAboutFarm, infoAboutFarmer


        } = this.state;

        return (
            <>
                <Sidebar/>


                <div>
                    {/*<div className="header_wrapper">*/}
                    {/*    <div className="header_logo">*/}
                    {/*        <img src={require('../../assets/img/datedish logo .png')}/>*/}
                    {/*    </div>*/}
                    {/*    <div className="header_search">*/}
                    {/*    </div>*/}
                    {/*    <div className="bell_icon">*/}
                    {/*        <i className="fas fa-bell"></i>*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                    <div className="body_container">
                        <div className="body_inner_wrapper">

                            {this.state.loader ? <Loader/> :
                                (<>

                                    <header className="header_content">
                                        <p>User Details</p>
                                    </header>

                                    <div className="main_content">
                                        {userProfile.profileCover ?
                                            <Row>
                                                <Col md="1">
                                                    <p>Profile pic:</p>
                                                </Col>
                                                <Col md="">
                                                    <div className="  file_uploader bg_size "
                                                         style={{backgroundImage: `url(${userProfile ? userProfile.profileCover : ''})`}}>


                                                    </div>


                                                </Col>

                                            </Row>
                                            : ''}
                                        <br/>

                                        <Row>

                                            <Col className="farm_page ">

                                                <Row>
                                                    <Col lg="2" md="3">

                                                        <p> Name:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled type="text"
                                                                      name="farmName"
                                                                      value={userProfile ? (userProfile.firstName + ' ' + userProfile.lastName) : ''}
                                                        />

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="2" md="3">
                                                        <p> Email:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled type="text"
                                                                      name="farmName"
                                                                      value={userProfile ? userProfile.email : ''}
                                                        />

                                                    </Col>
                                                </Row>
                                            </Col>

                                                <Col className="farm_page ">

                                                <Row>

                                                    <Col lg="2" md="3">
                                                        <p>About me:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled type="text"
                                                                      name="shortInfo"
                                                                      value={userProfile ? userProfile.aboutMe : ''}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>

                                                    <Col lg="2" md="3">
                                                        <p>Gender:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled type="text"
                                                                      name="shortInfo"
                                                                      value={userProfile ? (userProfile.gender == 1 ? "Male" : (userProfile.gender == 2 ? "Female" : (userProfile.gender == 3 ? "Others" : ""))) : ''}
                                                        />
                                                    </Col>
                                                </Row>

                                            </Col>


                                        </Row>

                                        <Row>
                                            {/*{userProfile.profileCoverPhotos?*/}
                                            <Col md="1">
                                                <p>Dished You</p>
                                            </Col>
                                            {/*:''}*/}
                                            <Col md="10">
                                                <div
                                                    className="fileupload_wraper"
                                                >
                                                    {/*,,*/}
                                                    {dishedYou && dishedYou.map((value, i) => (

                                                        <>
                                                            <Row>
                                                                <Col>
                                                                    <div className="file_uploader bg_size col"
                                                                         style={{backgroundImage: `url(${value.profileCover})`}}>
                                                                    </div>
                                                                </Col>
                                                                <Col className="col">
                                                                    {/*{value.userId},*/}
                                                                    <td>
                                                                        <Link
                                                                        to={`/user-info/${value.userId}`}>{value.firstName} {value.lastName}</Link>
                                                                    </td>

                                                                    <br/>
                                                                    <td>
                                                                        {(value.gender == 0 ? "Male" : '')}
                                                                        {(value.gender == 1 ? "Male" : '')}
                                                                        {(value.gender == 2 ? "Female" : '')}
                                                                        {(value.gender == 3 ? "Others" : '')}</td>
                                                                    {this.ConvertDob(value.dob)}<br/>
                                                                    <td>   {value.claimedStatus==1?"Claimed ":"unclaimed "} </td><br/>

                                                                  <td>  Total dishes: {value.dishes.dish}</td><br/>
                                                                   <td>dishAttractive: {value.dishes.dishAttractive}| dishLikable:{value.dishes.dishLikable}| dishTrustworthy:{value.dishes.dishTrustworthy}</td><br/>

                                                                    <td>
                                                                        No. of Users : {value.totalRatedUser}


                                                                    <div className="star-ratings-css">
                                                                        <div className="star-ratings-css-top" style={{"width": "50%"}}>
                                                                            <span>★</span><span>★</span><span>★</span><span>★</span><span>★</span>
                                                                        </div>
                                                                        <div className="star-ratings-css-bottom" style={{"width": "50%"}}>
                                                                            <span>★</span><span>★</span><span>★</span><span>★</span><span>★</span>
                                                                        </div>
                                                                    </div>
                                                                    </td>
                                                                </Col></Row>


                                                        </>

                                                    ))
                                                    }

                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {/*{userProfile.profileCoverPhotos?*/}
                                            <Col md="1">
                                                <p>you Dished</p>
                                            </Col>
                                            {/*:''}*/}
                                            <Col md="10">
                                                <div
                                                    className="fileupload_wraper"
                                                >

                                                    {youDished && youDished.map((value, i) => (

                                                        <>
                                                            <Row>
                                                                <Col>
                                                                    <div className="file_uploader bg_size col"
                                                                         style={{backgroundImage: `url(${value.profileCover})`}}>
                                                                    </div>
                                                                </Col>
                                                                <Col className="col">
                                                                    {/*{value.userId},*/}
                                                                    <td>
                                                                        <Link
                                                                        to={`/user-info/${value.userId}`}>{value.firstName} {value.lastName}</Link>
                                                                    </td>

                                                                    <br/>
                                                                    <td>
                                                                        {(value.gender == 0 ? "Male" : '')}
                                                                        {(value.gender == 1 ? "Male" : '')}
                                                                        {(value.gender == 2 ? "Female" : '')}
                                                                        {(value.gender == 3 ? "Others" : '')}</td>
                                                                    {this.ConvertDob(value.dob)}<br/>
                                                                    <td> {value.claimedStatus==1?"Claimed":"unclaimed"}</td><br/>
                                                                    {/*<td> {value.city}</td>*/}
                                                                    {/*<td> {value.state}</td>*/}
                                                                    {/*<td> {value.country}</td>*/}
                                                                    {/*<td> {value.zipCode}</td>*/}
                                                                    <td>  Total dishes: {value.dishes.dish}</td><br/>

                                                                    <td>dishAttractive: {value.dishes.dishAttractive}| dishLikable:{value.dishes.dishLikable}| dishTrustworthy:{value.dishes.dishTrustworthy}</td><br/>

                                                                    <td> No. of Users: {value.totalRatedUser}
                                                                         <div className="star-ratings-css">
                                                                        <div  className="star-ratings-css-top" style={{"width": "60%"}}>
                                                                            <span>★</span><span>★</span><span>★</span><span>★</span><span>★</span>
                                                                        </div>
                                                                        <div className="star-ratings-css-bottom"  style={{"width": "40%"}}>
                                                                            <span>★</span><span>★</span><span>★</span><span>★</span><span>★</span>
                                                                        </div>
                                                                    </div></td>


                                                                </Col></Row>


                                                        </>

                                                    ))
                                                    }
                                                </div>
                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col md="8">
                                                <div className="text-right">
                                                    <Link className="btn btn-warning text-light"
                                                          variant="warning"
                                                          to={`/users`}>Back</Link>
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>

                                </>)
                            }
                        </div>
                    </div>

                </div>
            </>)
    }

}

function mapStateToProps(state) {
    const {props, login, user, mapFarmReducer, farm} = state;

    return {
        props,
        login,
        user, mapFarmReducer,
        farm
    }
}

export default connect(mapStateToProps)(Dishes);
