import React, {Component} from 'react'
import {Col, Form, Row, Button} from 'react-bootstrap'
import Sidebar from "../common/sidebar";
import {usersActions} from "../../_actions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import _ from 'lodash';
import moment from "moment-timezone";
import {notify} from '../../components/common/Toster'
import Loader from '../../components/common/Loader'


function toFeet(n) {
    var realFeet = ((n*0.393700) / 12);
    var feet = Math.floor(realFeet);
    var inches = Math.round((realFeet - feet) * 12);


    return feet + " feet " + inches + ' inches ';
}


class UserDetails extends Component {

    constructor(props) {
        super(props);


        this.state = {

            errors: '',
            success: '',
            userInfo: [],
            isEdit: false,
            farmImagePreview: '', farmImageErrorMsg: '', farmMediaErrorMsg: '', farmImage: [],
            farmLogoPreview: '', farmLogo: '', farmLogoErrorMsg: '',
            farmVideoPreview: '', farmVideo: '', farmVideoErrorMsg: '',
            userId: localStorage.getItem('userId'),
            farmName: '', farmStatus: '', shortInfo: '', infoAboutFarm: '', infoAboutFarmer: '', description: '',
            farmId: '',
            userProfile: [],
            firstName: '',
            lastName: '',
            email: '',
            farmMedia: [],
            farmImage1Preview: [],
            media: [],
            valiDate: true, loader: false,

            address: '',
            addressLine1: '',
            addressLine1ErrorMsg: '',
            addressLine2: '',
            state: '',
            postalCode: '',
            city: '',
            lat: 0,
            lng: 0,
            firstNameErrorMsg: '',
            lastNameErrorMsg: '',
            emailErrorMsg: '',

        }

    }

    componentDidMount() {
        this.getUserDetails()

    }


    ConvertDob = (dob) => {
        // let data =        Date(dob)

        var data = new Date(dob * 1000);
        // let year = data.getFullYear(); // 2020


        let data2 = moment(data).format("MM/DD/YYYY")
        return data2
    }
    checkInterest = (interest) => {

        // let data =        Date(dob)
        let a = ''
        !_.isEmpty(interest) && interest.map((val, i = 0) => {
            return a = a + val + ','
        })

        return a
    }


    getUserDetails = () => {
        let {userId, farmLogo, farmImage, farmVideo, farmLogoPreview, farmVideoPreview, farmName, farmStatus, infoAboutFarm, infoAboutFarmer, description} = this.state;
        this.setState({errors: ''});

        let self = this
        this.setState({loader: true});
        let params = `?userId=${this.props.match.params.id}`

        let {dispatch} = this.props;
        dispatch(usersActions.getUserDetail(params)).then(data => {
            if (!_.isEmpty(data)) {
                let userProfile = data.responseData.userProfile
                self.setState({loader: false})

                if (data.statusCode == 1) {

                    self.setState({
                        userProfile: userProfile,
                        firstName: userProfile.firstName,
                        lastName: userProfile.lastName,
                        email: userProfile.email,

                    })
                } else {
                    self.setState({errors: data.error.responseMessage})
                }
            }

        });

    }

    onKeyPress(e) {
        e = e || window.event;
        var charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
        var charStr = String.fromCharCode(charCode);

        if (!charStr.match(/^[0-9]+$/))
            e.preventDefault();
    }
    handleChanged = (e) => {
        this.setState({
            errors: '',
            firstNameErrorMsg: '',
            lastNameErrorMsg: '',
            emailErrorMsg: '',
            [e.target.name]: e.target.value,

        });
    }
    editMode = () => {
        this.setState({isEdit: true});
    }
    viewMode = () => {
        this.setState({isEdit: false});
    }
    handleProfileValidation = () => {
        let validate = true;
        let {
            firstName,
            lastName,
            email,
        } = this.state;
        
        if (firstName === '' || firstName === undefined) {
            validate = false;

            this.setState({
                firstNameErrorMsg: 'Please enter first name.'
            })
        } else {
            this.setState({
                firstNameErrorMsg: ''
            })
        }

        if (lastName === '' || lastName === undefined) {
            validate = false;

            this.setState({
                lastNameErrorMsg: 'Please enter last name.'
            })
        } else {
            this.setState({
                lastNameErrorMsg: ''
            })
        }

        if (email === '' || email === undefined) {
            validate = false;

            this.setState({
                emailErrorMsg: 'Please enter email.'
            })
        } else {
            this.setState({
                emailErrorMsg: ''
            })
        }


        
        

        return validate
    }
    handleEditSubmit = (e) => {
        let {
            shortInfo,firstName,lastName, email, userProfile, isEdit, media, farmMediaErrorMsg, farmImage1Preview,
            farmLogoPreview, farmLogoErrorMsg, farmNameErrorMsg, infoAboutFarmErrorMsg, infoAboutFarmerErrorMsg, addressLine1ErrorMsg
            , farmName, infoAboutFarm, infoAboutFarmer


        } = this.state;

        this.setState({errors: ''});
        e.preventDefault()

        let self = this
        if (this.handleProfileValidation()) {

            let obj = new URLSearchParams()
            obj.append('firstName', firstName.charAt(0).toUpperCase() + firstName.slice(1));
            obj.append('lastName', lastName.charAt(0).toUpperCase() + lastName.slice(1));
            obj.append('email', email);
            obj.append('userId', this.props.match.params.id);
            self.setState({loader: true})
            let {dispatch} = this.props;

            dispatch(usersActions.editUserProfile(obj))

                .then((data) => {
                    if (data.statusCode == 1) {
                        notify('success', data.responseData.responseMessage)
                        self.setState({
                            loader: false,
                            isEdit: false,
                        })
                    } else {
                        self.setState({loader: false,})
                        self.setState({errors: data.data.error.responseMessage})
                    }

                }).catch(err => {

                self.setState({loader: false,})
                this.setState({isloader: false})
                notify('error', err.data.error.responseMessage)
            })
        }
    }


    render() {
        let {
            shortInfo, firstName,lastName, email, userProfile, isEdit, media, farmMediaErrorMsg, farmImage1Preview,
            farmLogoPreview, farmLogoErrorMsg, farmNameErrorMsg, infoAboutFarmErrorMsg, infoAboutFarmerErrorMsg, addressLine1ErrorMsg
            , farmName, infoAboutFarm, infoAboutFarmer, firstNameErrorMsg,lastNameErrorMsg, emailErrorMsg, loader


        } = this.state;

        return (
            <>
                <Sidebar/>


                <div>



                    <div className="body_container">
                        <div className="body_inner_wrapper">

                            {this.state.loader ?  <Loader/> :
                                (<>

                                    <header className="header_content">
                                        <p>User Details</p>
                                    </header>

                                    <div className="main_content">
                                        {userProfile.profileCover ?
                                            <Row>
                                                <Col md="1">
                                                    <p>Profile pic:</p>
                                                </Col>
                                                <Col md="">
                                                    <div className="  file_uploader bg_size "
                                                         style={{backgroundImage: `url(${userProfile ? userProfile.profileCover : ''})`}}>


                                                    </div>


                                                </Col>

                                            </Row>
                                            : ''}
                                        <br/>

                                        <Row>

                                            <Col className="farm_page ">

                                                <Row>
                                                    <Col lg="2" md="3">

                                                        <p> First Name:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control  disabled={isEdit? '': true } type="text"
                                                                      name="firstName"
                                                                      onChange={this.handleChanged}
                                                                      value={firstName}
                                                        />
                                                        {firstNameErrorMsg ?
                                                                                        <div
                                                                                            className="error">{firstNameErrorMsg}</div> : null}
                                                                                  

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="2" md="3">

                                                        <p> Last Name:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control  disabled={isEdit? '': true } type="text"
                                                                      name="lastName"
                                                                      onChange={this.handleChanged}
                                                                      value={lastName}
                                                        />
                                                        {lastNameErrorMsg ?<div
                                                                                            className="error">{lastNameErrorMsg}</div> : null}
                                                                                  

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="2" md="3">
                                                        <p> Email:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled={isEdit? '': true } type="text"
                                                                      name="email"
                                                                      onChange={this.handleChanged}
                                                                      value={email}
                                                        />
                                                         {emailErrorMsg ?
                                                                                        <div
                                                                                            className="error">{emailErrorMsg}</div> : null}

                                                    </Col>
                                                </Row>
                                                
                                                <Row>
                                                    
                                                <Col lg="2" md="3">
                                                    <p> Phone:</p>
                                                </Col>
                                                <Col md="7">
                                                    {/*<div className="form-control">{userProfile.countryCode} {userProfile.phone}</div>*/}
                                                    <Form.Control disabled type="text"
                                                                  name="farmName"
                                                                  value={userProfile.mobile ?("+"+ userProfile.countryCode  + ' ' +userProfile.mobile ) : ''}
                                                    />

                                                </Col>
                                            </Row>
                                                <Row>

                                                    <Col lg="2" md="3">
                                                        <p>Username:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled type="text"
                                                                      name="shortInfo"
                                                                      value={userProfile ? userProfile.userName : ''}
                                                        />
                                                    </Col>
                                                </Row>

                                                <Row>

                                                    <Col lg="2" md="3">
                                                        <p>Gender:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled type="text"
                                                                      name="shortInfo"
                                                                      value={userProfile ? (userProfile.gender == 1 ? "Male" : (userProfile.gender == 2 ? "Female" : (userProfile.gender == 3 ? "Others" : ""))) : ''}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>


                                                    <Col lg="2" md="3">
                                                        <p>State:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled
                                                                      name="smoking"
                                                                      value={userProfile ? userProfile.state : ''}
                                                                      className="form-control"
                                                        >
                                                        </Form.Control>
                                                    </Col>
                                                </Row>
                                                <Row>


                                                    <Col lg="2" md="3">
                                                        <p>DOB:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled
                                                                      name="infoAboutFarm"
                                                                      value={userProfile ? this.ConvertDob(userProfile.dob) : ''}
                                                                      className="form-control"
                                                        >
                                                        </Form.Control>
                                                    </Col>
                                                </Row>
                                                <Row>


                                                    <Col lg="2" md="3">
                                                        <p>Smoking:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled
                                                                      name="smoking"
                                                                      value={userProfile ? (userProfile.smoking == 1 ? "Occasionally" : (userProfile.smoking == 2 ? "Never" : (userProfile.smoking == 3 ? "Regularly" : ''))) : ''}

                                                                      className="form-control"
                                                        >
                                                        </Form.Control>
                                                    </Col>
                                                </Row>
                                                <Row>


                                                    <Col lg="2" md="3">
                                                        <p>Interest:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <textarea disabled type="textarea"  rows="4" cols="28"
                                                                      name="infoAboutFarm"
                                                                      value={userProfile ? (this.checkInterest(userProfile.interest)) : ''}
                                                                      className="form-control"
                                                        >
                                                        </textarea>
                                                    </Col>
                                                </Row>

                                            </Col>

                                            <Col className="farm_page ">



                                                <Row>


                                                    <Col lg="2" md="3">
                                                        <p>City:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled
                                                                      name="smoking"
                                                                      value={userProfile ? userProfile.city : ''}
                                                                      className="form-control"
                                                        >
                                                        </Form.Control>
                                                    </Col>
                                                </Row>

                                                <Row>


                                                    <Col lg="2" md="3">
                                                        <p>Country:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled
                                                                      name="smoking"
                                                                      value={userProfile ? userProfile.country : ''}
                                                                      className="form-control"
                                                        >
                                                        </Form.Control>
                                                    </Col>
                                                </Row>
                                                <Row>


                                                    <Col lg="2" md="3">
                                                        <p>Drinking:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled
                                                                      name="infoAboutFarm"
                                                                      value={userProfile ? (userProfile.drinking == 1 ? "Occasionally" : (userProfile.drinking == 2 ? "Never" : (userProfile.drinking == 3 ? "Regularly" : ''))) : ''}
                                                                      className="form-control"
                                                        >
                                                        </Form.Control>
                                                    </Col>
                                                </Row>
                                                <Row>


                                                    <Col lg="2" md="3">
                                                        <p>Looking For:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled
                                                                      name="infoAboutFarm"
                                                                      value={userProfile ? (userProfile.lookingFor == 1 ? "Men" : (userProfile.lookingFor == 2 ? "Women" : (userProfile.lookingFor == 3 ? "Gender Fluids" : (userProfile.lookingFor == 4 ? "Every one" : "")))) : ''}

                                                                      className="form-control"
                                                        >
                                                        </Form.Control>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="2" md="3">

                                                        <p>Height:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled
                                                                      name="infoAboutFarmer"
                                                                      value={userProfile.height  ? toFeet(userProfile.height ): ''}
                                                                      className="form-control"></Form.Control>
                                                    </Col>
                                                </Row>
                                                <Row>


                                                    <Col lg="2" md="3">
                                                        <p>Zodiac:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled
                                                                      name="smoking"
                                                                      value={userProfile ? userProfile.zodiac : ''}
                                                                      className="form-control"
                                                        >
                                                        </Form.Control>
                                                    </Col>
                                                </Row>


                                                <Row>

                                                    <Col lg="2" md="3">
                                                        <p>Relation ship Status:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <Form.Control disabled type="text"
                                                                      name="shortInfo"
                                                                      value={userProfile ? (userProfile.relationShipStatus == 1 ? "single" : (userProfile.relationShipStatus == 2 ? "In a relationship" : (userProfile.relationShipStatus == 3 ? "Married" : (userProfile.relationShipStatus == 4 ? "It's complicated" : '')))) : ''}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>

                                                    <Col lg="2" md="3">
                                                        <p>About me:</p>
                                                    </Col>
                                                    <Col md="7">
                                                        <textarea disabled type="text" rows="4" cols="28" className="form-control"
                                                                      name="shortInfo"
                                                                      value={userProfile ? userProfile.aboutMe : ''}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>

                                        </Row>


                                        {userProfile.profileCoverPhotos && userProfile.profileCoverPhotos.length > 1 ?
                                            <Row>

                                                <Col md="1">
                                                    <p>Profile cover images:</p>
                                                </Col>

                                                <Col md="10">
                                                    <div
                                                        className="fileupload_wraper"
                                                    >
                                                        {userProfile.profileCoverPhotos && userProfile.profileCoverPhotos.map((value, i) => (


                                                            <div className="file_uploader bg_size"
                                                                 style={{backgroundImage: `url(${value.url})`}}>
                                                            </div>


                                                        ))}
                                                    </div>
                                                </Col>
                                            </Row>
                                            : ''}
                                            {isEdit ? <Row >
                                                    <Col md="8" className="text-right">
                                                        <Button 
                                                        onClick={this.handleEditSubmit}
                                                                variant="warning"
                                                                className="mr-2">Save</Button>
                                                        &nbsp;

                                                        <Button onClick={this.viewMode}
                                                                variant="dark">Cancel</Button>
                                                                &nbsp;
                                                            <Link className="btn btn-warning text-light mr-2"
                                                                variant="warning"
                                                                to={`/users`}>Back</Link>

                                                    </Col>
                                                </Row>:

                                                <Row>
                                                    <Col md="8" className="text-right">
                                                    <Button onClick={this.editMode} variant="warning" className="mr-2">Edit</Button>
                                                    &nbsp;
                                                            <Link className="btn btn-warning text-light"
                                                                variant="warning"
                                                                to={`/users`}>Back</Link>
                                                    </Col>
                                                </Row>
                                        }
                                        

                                    </div>

                                </>)
                            }
                        </div>
                    </div>

                </div>
            </>)
    }

}

function mapStateToProps(state) {
    const {props, login, user, mapFarmReducer, farm} = state;

    return {
        props,
        login,
        user, mapFarmReducer,
        farm
    }
}

export default connect(mapStateToProps)(UserDetails);
