import promise from 'bluebird';
import {usersConstants} from '../_constants';
import {usersService} from "../_services";
import {ResponseFilter} from "../config/response-handler";

// export const usersConstants = {
//     LOGIN_REQUEST: 'LOGIN_REQUEST',
//     LOGIN_SUCCESS: 'LOGIN_SUCCESS',
//     LOGIN_ERROR: 'LOGIN_ERROR',
//
//
//     GET_USER_DISHES_REQUEST: 'GET_USER_DISHES_REQUEST',
//     GET_USER_DISHES_SUCCESS: 'GET_USER_DISHES_SUCCESS',
//     GET_USER_DISHES_ERROR: 'GET_USER_DISHES_ERROR',
//
//     GET_ADMIN_DETAIL_REQUEST: 'GET_ADMIN_DETAIL_REQUEST',
//     GET_ADMIN_DETAIL_SUCCESS: 'GET_ADMIN_DETAIL_SUCCESS',
//     GET_ADMIN_DETAIL_ERROR: 'GET_ADMIN_DETAIL_ERROR',
//
//     GET_USER_REQUEST: 'GET_USER_REQUEST',
//     GET_USER_SUCCESS: 'GET_USER_SUCCESS',
//     GET_USER_ERROR: 'GET_USER_ERROR',
//
//     EDIT_PROFILE_REQUEST: 'EDIT_PROFILE_REQUEST',
//     EDIT_PROFILE_SUCCESS: 'EDIT_PROFILE_SUCCESS',
//     EDIT_PROFILE_ERROR: 'EDIT_PROFILE_ERROR',
//
//     CHANGE_PASS_REQUEST: 'CHANGE_PASS_REQUEST',
//     CHANGE_PASS_SUCCESS: 'CHANGE_PASS_SUCCESS',
//     CHANGE_PASS_ERROR: 'CHANGE_PASS_ERROR',
//
//     RESET_REQUEST: 'RESET_REQUEST',
//     RESET_SUCCESS: 'RESET_SUCCESS',
//     RESET_ERROR: 'RESET_ERROR',
//
//     FORGOT_REQUEST: 'FORGOT_REQUEST',
//     FORGOT_SUCCESS: 'FORGOT_SUCCESS',
//     FORGOT_ERROR: 'FORGOT_ERROR',
//
//     GET_ADMIN_USER_DETAIL_REQUEST: 'GET_ADMIN_USER_DETAIL_REQUEST',
//     GET_ADMIN_USER_DETAIL_SUCCESS: 'GET_ADMIN_USER_DETAIL_SUCCESS',
//     GET_ADMIN_USER_DETAIL_ERROR: 'GET_ADMIN_USER_DETAIL_ERROR',
//
//     DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
//     DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
//     DELETE_USER_ERROR: 'DELETE_USER_ERROR',
//
//     ADD_EXAM_REQUEST: 'ADD_EXAM_REQUEST',
//     ADD_EXAM_SUCCESS: 'ADD_EXAM_SUCCESS',
//     ADD_EXAM_ERROR: 'ADD_EXAM_ERROR',
//
//     EDIT_EXAM_REQUEST: 'EDIT_EXAM_REQUEST',
//     EDIT_EXAM_SUCCESS: 'EDIT_EXAM_SUCCESS',
//     EDIT_EXAM_ERROR: 'EDIT_EXAM_ERROR',
//
//     GET_EXAM_REQUEST: 'GET_EXAM_REQUEST',
//     GET_EXAM_SUCCESS: 'GET_EXAM_SUCCESS',
//     GET_EXAM_ERROR: 'GET_EXAM_ERROR',
//
//     EDIT_SETTING_REQUEST: 'EDIT_SETTING_REQUEST',
//     EDIT_SETTING_SUCCESS: 'EDIT_SETTING_SUCCESS',
//     EDIT_SETTING_ERROR: 'EDIT_SETTING_ERROR',
//
//     ADD_SETTING_REQUEST: 'ADD_SETTING_REQUEST',
//     ADD_SETTING_SUCCESS: 'ADD_SETTING_SUCCESS',
//     ADD_SETTING_ERROR: 'ADD_SETTING_ERROR',
//
//     DELETE_EXAM_REQUEST: 'DELETE_EXAM_REQUEST',
//     DELETE_EXAM_SUCCESS: 'DELETE_EXAM_SUCCESS',
//     DELETE_EXAM_ERROR: 'DELETE_EXAM_ERROR',
//
// };

export const usersActions = {
    login,
    getUser,
    getUserDetail,
    getAllDishes,
    getAdminDetail,
    changeUserStatus,
    editProfile,
    editUserProfile,
    forgot, reset,
    deleteUser,
    deleteUserDish,
    getExam,
    deleteExam,
    changePassword,
    changeUserPassword,

    getSetting,
    addSetting,
    editSetting,
};


function login(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.login(params)
                .then(response => {
                    resolve(response);
                    let {serverResponseData} = ResponseFilter(response);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(loginRequest) {
        return {type: usersConstants.LOGIN_REQUEST, loginRequest}
    }

    function success(loginSuccess) {
        return {type: usersConstants.LOGIN_SUCCESS, loginSuccess}
    }

    function failure(loginFail) {
        return {type: usersConstants.LOGIN_ERROR, loginFail}
    }
}

function getUser(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.getUser(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(getUserRequest) {
        return {type: usersConstants.GET_USER_REQUEST, getUserRequest}
    }

    function success(getUserSuccess) {
        return {type: usersConstants.GET_USER_SUCCESS, getUserSuccess}
    }

    function failure(getUserFail) {
        return {type: usersConstants.GET_USER_ERROR, getUserFail}
    }
}

function getAdminDetail(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.getAdminDetail(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(getAdminDetailRequest) {
        return {type: usersConstants.GET_ADMIN_DETAIL_REQUEST, getAdminDetailRequest}
    }

    function success(getAdminDetailSuccess) {
        return {type: usersConstants.GET_ADMIN_DETAIL_SUCCESS, getAdminDetailSuccess}
    }


    function failure(getAdminDetailFail) {
        return {type: usersConstants.GET_ADMIN_DETAIL_ERROR, getAdminDetailFail}
    }
}

function changePassword(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.changePassword(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(editPassRequest) {
        return {type: usersConstants.CHANGE_PASS_REQUEST, editPassRequest}
    }

    function success(editPassSuccess) {
        return {type: usersConstants.CHANGE_PASS_SUCCESS, editPassSuccess}
    }

    function failure(editPassFail) {
        return {type: usersConstants.CHANGE_PASS_ERROR, editPassFail}
    }
}

function changeUserPassword(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.changeUserPassword(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(editPassRequest) {
        return {type: usersConstants.CHANGE_USER_PASS_REQUEST, editPassRequest}
    }

    function success(editPassSuccess) {
        return {type: usersConstants.CHANGE_USER_PASS_SUCCESS, editPassSuccess}
    }

    function failure(editPassFail) {
        return {type: usersConstants.CHANGE_USER_PASS_ERROR, editPassFail}
    }
}

function editProfile(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.editProfile(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(editProfileRequest) {
        return {type: usersConstants.EDIT_PROFILE_REQUEST, editProfileRequest}
    }

    function success(editProfileSuccess) {
        return {type: usersConstants.EDIT_PROFILE_SUCCESS, editProfileSuccess}
    }

    function failure(editProfileFail) {
        return {type: usersConstants.EDIT_PROFILE_ERROR, editProfileFail}
    }
}
function editUserProfile(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.editUserProfile(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(editUserProfileRequest) {
        return {type: usersConstants.EDIT_USER_PROFILE_REQUEST, editUserProfileRequest}
    }

    function success(editUserProfileSuccess) {
        return {type: usersConstants.EDIT_USER_PROFILE_SUCCESS, editUserProfileSuccess}
    }

    function failure(editUserProfileFail) {
        return {type: usersConstants.EDIT_USER_PROFILE_ERROR, editUserProfileFail}
    }
}

function getUserDetail(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.getUserDetail(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(getUserDetailRequest) {
        return {type: usersConstants.GET_ADMIN_USER_DETAIL_REQUEST, getUserDetailRequest}
    }

    function success(getUserDetailSuccess) {
        return {type: usersConstants.GET_ADMIN_USER_DETAIL_SUCCESS, getUserDetailSuccess}
    }

    function failure(getUserDetailFail) {
        return {type: usersConstants.GET_ADMIN_USER_DETAIL_ERROR, getUserDetailFail}
    }
}

function getAllDishes(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.getAllDishes(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(getAllDishesRequest) {
        return {type: usersConstants.GET_USER_DISHES_REQUEST, getAllDishesRequest}
    }

    function success(getAllDishesSuccess) {
        return {type: usersConstants.GET_USER_DISHES_SUCCESS, getAllDishesSuccess}
    }

    function failure(getAllDishesFail) {
        return {type: usersConstants.GET_USER_DISHES_ERROR, getAllDishesFail}
    }
}


function forgot(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.forgot(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(forgotRequest) {
        return {type: usersConstants.FORGOT_REQUEST, forgotRequest}
    }

    function success(forgotSuccess) {
        return {type: usersConstants.FORGOT_SUCCESS, forgotSuccess}
    }

    function failure(forgotFail) {
        return {type: usersConstants.FORGOT_ERROR, forgotFail}
    }
}

function reset(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.reset(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(resetRequest) {
        return {type: usersConstants.RESET_REQUEST, resetRequest}
    }

    function success(resetSuccess) {
        return {type: usersConstants.RESET_SUCCESS, resetSuccess}
    }

    function failure(resetFail) {
        return {type: usersConstants.RESET_ERROR, resetFail}
    }
}

function getExam(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.getExam(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(getExamRequest) {
        return {type: usersConstants.GET_EXAM_REQUEST, getExamRequest}
    }

    function success(getExamSuccess) {
        return {type: usersConstants.GET_EXAM_SUCCESS, getExamSuccess}
    }

    function failure(getExamFail) {
        return {type: usersConstants.GET_EXAM_ERROR, getExamFail}
    }
}

function deleteExam(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.deleteExam(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(deleteExamRequest) {
        return {type: usersConstants.DELETE_EXAM_REQUEST, deleteExamRequest}
    }

    function success(deleteExamSuccess) {
        return {type: usersConstants.DELETE_EXAM_SUCCESS, deleteExamSuccess}
    }

    function failure(deleteExamFail) {
        return {type: usersConstants.DELETE_EXAM_ERROR, deleteExamFail}
    }
}

function changeUserStatus(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.changeUserStatus(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(changeUserStatusRequest) {
        return {type: usersConstants.CHANGE_USER_STATUS_REQUEST, changeUserStatusRequest}
    }

    function success(changeUserStatusSuccess) {
        return {type: usersConstants.CHANGE_USER_STATUS_SUCCESS, changeUserStatusSuccess}
    }

    function failure(changeUserStatusFail) {
        return {type: usersConstants.CHANGE_USER_STATUS_ERROR, changeUserStatusFail}
    }
}
function deleteUser(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.deleteUser(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(deleteUserRequest) {
        return {type: usersConstants.DELETE_USER_REQUEST, deleteUserRequest}
    }

    function success(deleteUserSuccess) {
        return {type: usersConstants.DELETE_USER_SUCCESS, deleteUserSuccess}
    }

    function failure(deleteUserFail) {
        return {type: usersConstants.DELETE_USER_ERROR, deleteUserFail}
    }
}
function deleteUserDish(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.deleteUserDish(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(deleteUserDishRequest) {
        return {type: usersConstants.DELETE_USER_REQUEST, deleteUserDishRequest}
    }

    function success(deleteUserDishSuccess) {
        return {type: usersConstants.DELETE_USER_SUCCESS, deleteUserDishSuccess}
    }

    function failure(deleteUserDishFail) {
        return {type: usersConstants.DELETE_USER_ERROR, deleteUserDishFail}
    }
}

function getSetting(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.getSetting(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(getExamRequest) {
        return {type: usersConstants.GET_EXAM_REQUEST, getExamRequest}
    }

    function success(getExamSuccess) {
        return {type: usersConstants.GET_EXAM_SUCCESS, getExamSuccess}
    }

    function failure(getExamFail) {
        return {type: usersConstants.GET_EXAM_ERROR, getExamFail}
    }
}

function addSetting(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.addSetting(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(addExamRequest) {
        return {type: usersConstants.ADD_EXAM_REQUEST, addExamRequest}
    }

    function success(addExamSuccess) {
        return {type: usersConstants.ADD_EXAM_SUCCESS, addExamSuccess}
    }

    function failure(addExamFail) {
        return {type: usersConstants.ADD_EXAM_ERROR, addExamFail}
    }
}

function editSetting(params) {
    return function (dispatch) {
        return new promise(function (resolve, reject) {
            dispatch(request(params));
            usersService.editSetting(params)
                .then(response => {
                    let {serverResponseData} = ResponseFilter(response);
                    resolve(serverResponseData);
                    dispatch(success(serverResponseData));
                })
                .catch((err) => {
                    reject(err);
                    dispatch(failure(err));
                })
        })
    };

    function request(editSettingRequest) {
        return {type: usersConstants.EDIT_SETTING_REQUEST, editSettingRequest}
    }

    function success(editSettingSuccess) {
        return {type: usersConstants.EDIT_SETTING_SUCCESS, editSettingSuccess}
    }

    function failure(editSettingFail) {
        return {type: usersConstants.EDIT_SETTING_ERROR, editSettingFail}
    }
}
