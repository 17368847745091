import { combineReducers } from 'redux';
import { login, user } from './users.reducer';
import {report } from './reports.reducer';
const rootReducer = combineReducers({
  login,
  user,
  report,
});

export default rootReducer;
