import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/icomoon/style.css';
import './index.css';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import {createBrowserHistory} from 'history'
import {connect} from 'react-redux';
import axios from "axios";
import Page404 from './components/common/page404';

import Login from './components/auth/Login'
import Address from './components/view/address'
import UserList from './components/view/userList'
import UserDishes from './components/view/userDishes'
import ReasonList from './components/view/reasons'
import ReportList from './components/view/reports'
import UserDetail from './components/view/UserDetails'
import Setting from './components/view/settings'
import ForgotPassword from './components/auth/ForgotPassword'
import ResetPassword from './components/auth/ResetPassword'
import Dishes from "./components/view/dishes";

export const history = createBrowserHistory();


const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (localStorage.getItem('accessToken') ? <Component {...props} />: <Redirect to={{pathname: '/', state: {from: props.location}}}/>
    )}/>
)
const PrivateOuterRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => (localStorage.getItem('accessToken') ? <Redirect to={{pathname: '/users', state: {from: props.location}}}/>
            : <Component {...props} />
    )}/>
)
class Routes extends Component {
    constructor(props) {
        super(props);
        const {dispatch} = this.props;
        history.listen((location, action) => {

        });
    }


    render() {
        let accessToken = localStorage.getItem('accessToken');
        if (accessToken) {

            axios.defaults.headers.common['accessToken'] = `${accessToken}`;
        }
        axios.defaults.headers.common['Authorization'] = `${'Basic ZGF0ZWRpc2g6ZGF0ZWRpc2hAMTIz'}`;


        return (
            <>
                <Router history={history}>


                    <Switch>
                        <PrivateOuterRoute exact path="/" component={Login}/>
                        <PrivateOuterRoute exact path="/forgot-password" component={ForgotPassword}/>
                        <PrivateOuterRoute exact path="/reset-password/:id" component={ResetPassword}/>
                        <PrivateRoute path="/users" component={UserList}/>
                        <PrivateRoute path="/reports" component={ReportList}/>
                        <PrivateRoute path="/reasons" component={ReasonList}/>
                        <PrivateRoute path="/user-info/:id" component={UserDetail}/>
                        <PrivateRoute path="/settings" component={Setting}/>

                        // <PrivateOuterRoute exact path="/" component={Login}/>
                        <PrivateRoute path="/address" component={Address}/>
                        <PrivateRoute path="/dish/:id" component={Dishes}/>
                        <PrivateRoute path="/user-dish/:id" component={UserDishes}/>
                        // <PrivateRoute path="/user-info/:id" component={UserDetail}/>
                        // <PrivateRoute path="/settings" component={Setting}/>
                    </Switch>


                </Router>
            </>
        )
    }
}


function mapStateToProps(state) {
    const {alert} = state;
    return {
        alert
    };
}
export default connect(mapStateToProps)(Routes);
