// import React from 'react'
import React, {Component} from 'react';
import {Button, Col, Container, Form, Navbar, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom';

import {usersActions} from "../../_actions";
import {connect} from "react-redux";
import axios from "axios";
// import crypto from 'crypto';
// import CryptoJS from 'crypto-js';

class Login extends Component {

    constructor(props) {
        super(props);


        this.state = {

            email: '',
            password: '',
            userType: '1',
            lat: '',
            long: '',
            passwordErrorMsg: '',
            emailErrorMsg: '',
            errors: '',
            success: '',
            checkBoxValue: 0,
            rememberMe: true,
            showPass:false

        }
    }


    validateEmail(email) {
        const pattern = /(([a-zA-Z0-9\-?\.?]+)@(([a-zA-Z0-9\-_]+\.)+)([a-z]{2,3}))+$/;
        const result = pattern.test(email);
        return result;
    }


    handleSignInValidation = () => {
        let validate = true;
        let {email, password, userType} = this.state;


        if (email === '' || email === undefined) {
            validate = false;
            this.setState({
                emailErrorMsg: 'Please enter your email address.'
            })
        } else if (!this.validateEmail(String(email.toLowerCase()))) {
            validate = false;
            this.setState({emailErrorMsg: 'Please enter a valid email address.'})
        } else {
            this.setState({
                emailErrorMsg: ''
            })
        }

        if (password === '' || password === undefined) {
            validate = false;
            this.setState({
                passwordErrorMsg: 'Please enter your password.'
            })
        } else if (password.length <= 4) {
            validate = false;
            this.setState({
                passwordErrorMsg: 'Password must be greater than 4 digits.'
            })
        } else {
            this.setState({
                passwordErrorMsg: ''
            })
        }


        return validate
    }


//sign in
    handleSignInSubmit = e => {
        let {email, long, lat, password, userType} = this.state;
        this.setState({redirect: false, errors: ''});
        e.preventDefault();

        let self = this
        if (this.handleSignInValidation()) {
            this.setState({redirect: false, loader: true});

            let obj = {
                'email': email.toLowerCase(),
                'password': password,
            }

            let {dispatch} = this.props;
            dispatch(usersActions.login(obj)).then(data => {


                this.setState({loader: false})
                let getData = this.props.user;

                if (data.data.statusCode == 1) {
                    let userInfo = data.data.responseData.userProfile;
                    axios.defaults.headers.common['accessToken'] = `${data.data.responseData.accessToken}`;

                    localStorage.setItem('accessToken', data.data.responseData.accessToken);

                    self.props.history.push('/users');
                } else {

                    this.setState({errors: data.data.error.responseMessage})
                    // self.props.history.push('/');
                }
            });
        }
    }

    handleChanged = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value,
                // emailError: ''
            }
        );
    }


    handleCloseModal = () => {
        this.setState({
            rs: '',
            success: '',
            firstNameErrorMsg: '',
            errors: '',
            emailErrorMsg: '',
            passwordErrorMsg: '',
            confirmPasswordErrorMsg: '',
        })
    }
    showPass=(set)=>{
        this.setState({showPass:set})
    }
    render() {
        let {email,showPass, password, passwordErrorMsg, emailErrorMsg, errors, success} = this.state;
        return (
            <>
                <div className="bg-img">
                    <div className="content">
                        <header> <img src={require('../../assets/img/DDlogo.png')} alt=""/></header>
                        <form action="#">
                                         <div className="error"> {errors} </div>

                            <div className="field">
                                <span className="fa fa-user"></span>
                                <input autoComplete="off" type="email" name="email" value={email} placeholder="Email Address" onChange={this.handleChanged} required placeholder="Email or Phone"


                                                               placeholder="Email Address"/>
                            </div>
                            {emailErrorMsg ? <div className="error">{emailErrorMsg}</div> : null}

                            <div className="field space">
                                <span className="fa fa-lock"></span>

                                <input type="password" className="pass-key" required autoComplete="off" type={showPass?"text":"password"} onChange={this.handleChanged} name="password" value={password} placeholder="Password" />
                                    {showPass?<span className="show" onClick={()=>this.showPass(false)}>HIDE</span>:<span className="show" onClick={()=>this.showPass(true)}>SHOW</span>}
                            </div>
                            {passwordErrorMsg? <div className="error">{passwordErrorMsg}</div> : null}
                            <div className="pass">
                                <a href="/forgot-password">Forgot Password?</a>
                            </div>
                            <div className="field">
                                <input className="textboxcol" type="submit" onClick={this.handleSignInSubmit} value="LOGIN"/>
                                            {/*<Button type="submit" className="max-w-100" onClick={this.handleSignInSubmit} className="textboxcol "   variant="textboxcol">Sign In</Button>*/}

                            </div>
                        </form>
                    </div>
                </div>


                </>
            // <div className="login_bg">
            // <div className="body_container_wrapper login_page">
            //     <h1>
            //         <Navbar.Brand href="#home">
            //
            //         </Navbar.Brand>
            //     </h1>
            //     <div className="login_wrapper">
            //         <div className="mx-auto text-center login-main">
            //             <div className="logo mb-4">
            //                 <img src={require('../../assets/img/datedish logo .png')} alt=""/>
            //             </div>
            //             {/*<h6>Sign In</h6>*/}
            //             <div className="error"> {errors} </div>
            //             <div className="success"> {success} </div>
            //             <Form.Group>
            //                 <Form.Control autoComplete="off" type="email"  onChange={this.handleChanged}
            //                               name="email" value={email}
            //                               placeholder="Email Address"/>
            //                 {emailErrorMsg ? <div className="error">{emailErrorMsg}</div> : null}
            //
            //                 {/*<Form.Control  type="email" placeholder="email" />*/}
            //             </Form.Group>
            //             <Form.Group>
            //                 <Form.Control autoComplete="off" type="Password"  className="textboxcolor" onChange={this.handleChanged} name="password" value={password} placeholder="Password" />
            //                 {passwordErrorMsg ? <div className="error">{passwordErrorMsg}</div> : null}
            //
            //                 {/*<Form.Control  type="password" placeholder="password" />*/}
            //             </Form.Group>
            //             <Form.Group controlId="formBasicCheckbox" className="forgot_wrapper">
            //
            //                 <p><small><Link to="/">Forgot Password?</Link></small></p>
            //             </Form.Group>
            //             <Button type="submit" className="max-w-100" onClick={this.handleSignInSubmit} className="textboxcol "   variant="textboxcol">Sign In</Button>
            //         </div>
            //     </div>
            // </div>
            // </div>


        )
    }


}


function mapStateToProps(state) {
    const {props, login, user} = state;
    return {
        props,
        login,
        user
    }
}

export default connect(mapStateToProps)(Login);



