import axios from 'axios';
import API from '../config/API';

export const usersService = {
    login,
    getUser,
    getAllDishes,
    getUserDetail,
    getAdminDetail,
    editProfile,
    editUserProfile,
    changeUserStatus,
    deleteUser,
    deleteUserDish,

    forgot,
    changePassword,
    changeUserPassword,
    reset,
    getExam,
    deleteExam,

    getSetting,
    addSetting,
    editSetting,
}

const headersApplicationJson = {
    'Content-Type': 'application/json',
}
const headersMultipart = {
    "Content-type": "multipart/form-data",
}

function login(params) {
    return axios.post(API.LOGIN, params)
}

function forgot(params) {
    return axios.post(API.FORGOT, params)
}
function reset(params) {
    return axios.post(API.RESET, params)
}

function getExam(params) {
    return axios.get(`${API.GET_EXAM}${params?params:''}`);
}

function getUser(params) {
    return axios.get(`${API.GET_USER}/${params}`);
}

function getUserDetail(params) {
    return axios.get(`${API.GET_USER_DETAIL}/${params}`);
}
function getAllDishes(params) {
    return axios.get(`${API.GET_USER_DISHES}/${params}`);
}
function getAdminDetail(params) {
    return axios.get(`${API.GET_ADMIN_DETAIL}`);
}

function changePassword(params) {
    return axios.post(API.CHANGE_PASSWORD, params);
}
function changeUserPassword(params) {
    return axios.post(API.CHANGE_USER_PASSWORD, params);
}

function editProfile(params) {
    return axios.post(API.EDIT_PROFILE, params);
}
function editUserProfile(params) {
    return axios.post(API.EDIT_USER_PROFILE, params);
}

function deleteExam(params) {
    return axios.delete(API.DELETE_EXAM, {data:params})
}

function changeUserStatus(params) {
    return axios.post(API.CHANGE_USER_STATUS, params)
}
function deleteUser(params){
    return axios.post(`${API.DELETE_USER}/${params}`);

}
function deleteUserDish(params){
    return axios.post(`${API.DELETE_USER_DISH}/${params}`);

}


function getSetting(params) {
    return axios.get(`${API.GET_SETTING}`);
}

function addSetting(params) {
    return axios.post(API.ADD_SETTING,params);
}
function editSetting(params) {
    return axios.post(API.EDIT_SETTING,params);
}