import siteSetting from "./env";

export default {
    'AUTH': 'Basic ZGF0ZWRpc2g6ZGF0ZWRpc2hAMTIz',

    'LOGIN': siteSetting.api.url + "admin/login",
    'FORGOT': siteSetting.api.url + "admin/forgotPassword",
    'RESET': siteSetting.api.url + "admin/resetPassword",
    'GET_USER': siteSetting.api.url + "admin/getUserList",
    'GET_REASONS': siteSetting.api.url + "reason/getReason",
    'GET_ADDRESS': siteSetting.api.url + "address/getAllAddressForAdmin",
    'GET_ADMIN_DETAIL': siteSetting.api.url + "admin/getAdminInfo",
    'CHANGE_PASSWORD': siteSetting.api.url + "admin/changePassword",
    'CHANGE_USER_PASSWORD': siteSetting.api.url + "admin/resetPasswordOfUser",
    'EDIT_PROFILE': siteSetting.api.url + "admin/editProfile",
    'EDIT_USER_PROFILE': siteSetting.api.url + "admin/changeUserDetails",
    'CHANGE_USER_STATUS': siteSetting.api.url + "admin/blockUser",
    'DELETE_USER': siteSetting.api.url + "admin/adminDeleteUser",
    'DELETE_USER_DISH': siteSetting.api.url + "admin/adminDeleteUserDish",
    'GET_USER_DETAIL': siteSetting.api.url + "admin/getUserDetailForAdmin",
    'GET_USER_DISHES': siteSetting.api.url + "admin/getUserDishesForAdmin",



    'GET_REPORTS': siteSetting.api.url + "report/getReports",

}
