import {usersConstants} from '../_constants';

const initialState = {};

export function login(state = initialState, action) {

    switch (action.type) {
        case usersConstants.LOGIN_SUCCESS:

            let userInfo = action.loginSuccess;

            return userInfo;

        default:
            return state
    }
}


export function user(state = initialState, action) {
    switch (action.type) {
        case usersConstants.GET_USER_INFO_SUCCESS:
            let userInfo = action.getUserInfoSuccess;
            state.userInfo = userInfo
            return {...state};

        case usersConstants.GET_USER_SUCCESS:
            let getUserSuccessInfo = action.getUserSuccess;
            state.userInfo = getUserSuccessInfo
            return {...state};
        case usersConstants.GET_USER_DISHES_SUCCESS:
            let getAllDishesSuccessInfo = action.getAllDishesSuccess;
            state.userInfo = getAllDishesSuccessInfo
            return {getAllDishesSuccessInfo};


        case usersConstants.GET_ADMIN_DETAIL_SUCCESS:
            let getAdminDetailSuccessInfo = action.getAdminDetailSuccess;
            state.userInfo = getAdminDetailSuccessInfo
            return {...state};

        case usersConstants.GET_ADMIN_USER_DETAIL_SUCCESS:
            let getUserDetailSuccessInfo = action.getUserDetailSuccess;
            state.userInfo = getUserDetailSuccessInfo
            return {...state};

        case usersConstants.LOGOUT_SUCCESS:
            let logoutInfo = action.logoutSuccess;
            return {logoutInfo};


        case usersConstants.SIGNUP_SUCCESS:
            let signupInfo = action.signupSuccess;
            return {signupInfo};

        case usersConstants.FORGOT_SUCCESS:
            let forgotInfo = action.forgotSuccess;
            return forgotInfo;

        case usersConstants.RESET_SUCCESS:
            let resetInfo = action.resetSuccess;
            return resetInfo;

        case usersConstants.EDIT_PROFILE_SUCCESS:
            let editProfileInfo = action.editProfileSuccess;
            return editProfileInfo;


        case usersConstants.CHANGE_PASS_SUCCESS:
            let editPassInfo = action.editPassSuccess;
            return editPassInfo;


        case usersConstants.RESET_SUCCESS:
            let resetSuccessInfo = action.resetSuccess;
            return resetSuccessInfo;

        case usersConstants.FORGOT_SUCCESS:
            let forgotSuccessInfo = action.forgotSuccess;
            return forgotSuccessInfo;

        case usersConstants.GET_EXAM_SUCCESS:
            let getExamSuccessInfo = action.getExamSuccess;
            return getExamSuccessInfo;

        case usersConstants.DELETE_EXAM_SUCCESS:
            let deleteExamSuccessInfo = action.deleteExamSuccess;
            return deleteExamSuccessInfo;

        case usersConstants.EDIT_SETTING_SUCCESS:
            let editSettingSuccessInfo = action.editSettingSuccess;
            return editSettingSuccessInfo;


        default:
            return {...state}
    }
}
