// import React from 'react'
import React, {Component} from 'react';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import {Container, Navbar, Row, Col, Form, Button} from 'react-bootstrap'
import { isValidEmail } from '../../utils/Validation'
import Loader from '../common/Loader'
import {notify} from '../common/Toster'
import {usersActions} from "../../_actions";
import axios from "axios";
import {connect} from "react-redux";
// import Url from '../services/BaseUrl'

class ResetPassword extends Component {

    constructor(props) {
        super(props)


        this.state = {
            password: '',
            confirmPassword:'',
            errors:[],
            responseError:''

        }
      
    }
componentDidMount() {

}

    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, errors: [], responseError: '' })
    }


    handleValidate = () => {
        let validate = false;
        let errors = {};
        let { password,confirmPassword, desc, imageLocation, phone, countryCode } = this.state

        if (_.isEmpty(password)) {
            validate = true;
            errors['password'] = 'Password is required.';
        }
        if (_.isEmpty(confirmPassword)) {
            validate = true;
            errors['confirmPassword'] = 'Confirm password is required.';

        } else if (password !== confirmPassword){
            validate=true;
            errors['confirmPassword'] = 'Old paswword and comfirm password must be same.'
        }
        this.setState({ errors });
        return validate;
    }

   // Url.ADMIN_GET_PROFILE_TYPE.method, Url.ADMIN_GET_PROFILE_TYPE.url, "",
   handleResetSubmit = (e) => {
    e.preventDefault();
    if (!this.handleValidate()) {
        let { password } = this.state;
        //     localStorage.setItem('accessTokensssssssssssssss',this.props)
        // localStorage.setItem('accessTokensssssssssssssss',this.props,this.props.match.params.id)
        axios.defaults.headers.common['accessToken'] = `${this.props.match.params.id}`;

        let obj = {
            password: password,
        }
        let self=this;

        let {dispatch} = this.props;
        dispatch(usersActions.reset(obj)).then(data => {


            this.setState({loader: false})
            // let getData = this.props.user;

            if (data.statusCode == 1) {
                let success = data.responseData.message;
                // axios.defaults.headers.common['accessToken'] = `${data.data.responseData.accessToken}`;
this.setState({ success:success})
                // localStorage.setItem('accessToken', data.data.responseData.accessToken);

                // self.props.history.push('/');
            } else {

                this.setState({responseError: data.error.responseMessage})
                // self.props.history.push('/');
            }
        });
        // _Api(Url.SUPER_ADMIN_RESET_PASSWORD.method, Url.SUPER_ADMIN_RESET_PASSWORD.url, req)
        //         .then((resp) => {
        //             // this.setState({ driverInfo: resp.responseData.driverInfo, isLoader: false })
        //             notify('success', resp.responseData.message)
        //             window.location.href = '/';
        //             // this.closeModal()
        //             localStorage.clear('accessToken')
        //         })
    }
}




    goBack = () => {
        // this.props.history.goBack()
        this.props.history.push("/")
        // this.setState({ rs:'',success:'',firstNameErrorMsg:'', errors:'',emailErrorMsg:'',passwordErrorMsg:'',confirmPasswordErrorMsg:'', })
    }


//sign in
//     handleResetSubmit = e => {
//         let { email,long, lat,password,userType} = this.state;
//         this.setState({redirect: false, errors: ''});
//         e.preventDefault();
//         // let token= this.props.match.params.id;

//         let self=this
//         if (this.handleValidation()) {
//             this.setState({redirect: false,loader: true});
// let token= this.props.match.params.id;

//             let obj = {
//                 'newPassword': password,
//             }

//             let {dispatch} = this.props;
//             dispatch(usersActions.reset(obj,token)).then(data => {


//                 this.setState({loader: false})

//                 if (data.data.statusCode == 1) {
//                     let success = data.data.responseData.message
//                     this.setState({success: success})

//                 }
//                 else {
//                     this.setState({errors: data.data.error.responseMessage})
//                 }
//             });
//         }
//     }

  

    render() {
        let {email,password,passwordErrorMsg,emailErrorMsg,responseError,errors,success,confirmPasswordErrorMsg,confirmPassword}=this.state;
        return (

            <div className="body_container_wrapper login_page">
                <Container>

                    <div className="wrapforgot">
                        <Row>
                            <Col md="4" className="mx-auto text-center">
                                <div>
                                    <img  src={require('../../assets/img/datedish logo .png')}  />
                                </div>
                                <h6>Reset Password</h6>
                                <div className="error"> {responseError} </div>
                                <div className="success"> {success} </div>

                                <Form.Group>
                                    <Form.Control type="Password" onChange={this.handleOnChange}  name="password" value={password} placeholder="New Password"/>
                                    <span className="error">{errors['password']}</span>
                                    {/* {passwordErrorMsg ? <div className="error">{passwordErrorMsg}</div> : null} */}
                                </Form.Group> <Form.Group>

                                    <Form.Control type="Password" onChange={this.handleOnChange}  name="confirmPassword" value={confirmPassword} placeholder="Confirm Password"/>
                                    <span className="error">{errors['confirmPassword']}</span>
                                    
                                 
                                </Form.Group>
                                <Form.Group controlId="formBasicCheckbox" className="forgot_wrapper">
                                    <p><small><Link to="/">Login</Link></small></p>
                                </Form.Group>
                                { responseError ?<Button  onClick={this.goBack} variant="variant">Go Back</Button>:
                                <Button type="submit" onClick={this.handleResetSubmit} variant="dark">Submit</Button>}
                            </Col>
                        </Row>
                    </div>
                </Container>

            </div>


        )
    }


}

function mapStateToProps(state) {
    const {props, login, user} = state;
    return {
        props,
        login,
        user
    }
}

export default connect(mapStateToProps)(ResetPassword);






// export default Login


