import React, {Component} from 'react'
import {Button, Col, Form, Modal, Row} from 'react-bootstrap'

import {connect} from "react-redux";
import _ from 'lodash';
import Sidebar from '../common/sidebar'
import Pagination from "react-js-pagination";
import {Link} from "react-router-dom";
import moment from "moment-timezone"
import {reportsActions} from "../../_actions";
import Loader from '../../components/common/Loader'


class ReasonList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            orderInfo: [],
            ItemList: [],
            reId: [],
            excelFile: '',
            examDate: '',
            ItemId: [],
            itemId: [],

            sortField: '',
            sortType: 1,

            reasonErMsg: '',
            show: false,
            farmName: '',
            addExamPopup: false,
            messagePopup: 0, alertPopup: 0, emailPopup: 0,
            lat: 0, long: '',
            count: 30,
            totalCount: 0,
            ReportListCount: 0,
            dataCount: 0,
            filterHarvest: '',
            pageNo: 1,
            orderInfo1: [],
            userStatus: 0,
            profilePicPreview: '',

            examName: '',
            userId: '',
            deletePopup: false,
            examNameErrorMsg: '',
            examDateErrorMsg: '',
            reasonList: [],
            // userId: localStorage.getItem('userId'),
            profilePicErrorMsg: '',
            loader: true
        }

    }

    componentDidMount() {
        this.getReasons()

    }


    getReasons = () => {
        this.setState({errors: ''});

        let self = this


        let {dispatch} = this.props;
        // dispatch(orderActions.getAllOrderExcel(params)).then(data => {
        let {pageNo, count, sortField, sortType, search} = this.state

        // dispatch(orderActions.getAllOrderExcel(params)).then(data => {
        let params = `?pageNo=${pageNo - 1}&count=${count}&sortField=${sortField ? sortField : ''}&sortType=${sortType ? sortType : ''}&search=${search ? search : ''}`


        dispatch(reportsActions.getReasons(params)).then(data => {

            this.setState({loader: false})
            let reasonList = data.responseData.reasonList;
            let reasonListCounts = data.responseData.reasonListCount ? data.responseData.reasonListCount : 0;

            if (data.statusCode == 1) {

                this.setState({
                    reasonList: reasonList,
                    reasonListCounts: reasonListCounts,


                })
            } else {
                this.setState({errors: data.error.responseMessage})
            }
        });

    }
    handlePageChange = (pageNo) => {

        var self = this;
        this.setState({loader: false, pageNo: pageNo, count: 30}, () => {
            self.getReasons();
        });
    }
    handleChanged = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value,
                // emailError: ''
            }
        );
    }

    handleFilter = ele => {

        this.setState({
            loader: false, reasonErMsg: '', filterHarvest: ele, count: 10, pageNo: 1
        }, () => this.getOrderList())
    }

    handleClose = () => {
        this.setState({addExamPopup: false, deletePopup: false, showInvoice: false,})
    }

    ConvertDate = (created) => {
        let data = moment(created).format("MM/DD/YYYY")
        return data
    }
    ConvertDob = (dob) => {
        // let data =        Date(dob)
        let data = moment(dob).format("MM/DD/YYYY")
        return data
    }

    handleShowAddExam = (item) => {
        this.setState({addExamPopup: true, ItemList: item})
    }

    handleValidate = () => {
        let validate = true;
        if (this.state.examDate === "" || this.state.examDate === undefined) {
            validate = false;
            this.setState({
                examDateErrorMsg: 'Please enter exam date.'
            })
        } else {
            this.setState({
                examDateErrorMsg: ''
            })
        }
        if (this.state.examName === "" || this.state.examName === undefined) {
            validate = false;
            this.setState({
                examNameErrorMsg: 'Please enter exam name.'
            })
        } else {
            this.setState({
                examNameErrorMsg: ''
            })
        }

        return validate


    }


    handleMaltiSelct = (e, val) => {
        let {orderInfo, multiValue} = this.state;
        let self = this;
        this.setState({reasonErMsg: ''})
        multiValue = typeof this.state.multiValue === 'object' ? this.state.multiValue : []
        let reIdval = []

        if (this.state.reId.includes(val)) {

            for (var i = 0; i < this.state.reId.length; i++) {
                if (this.state.reId[i] === val) {
                    this.state.reId.splice(i, 1);
                }
            }
        } else {
            this.state.reId.push(val)
        }


        this.state.orderInfo.map(function (value, index) {

            if (value._id == val) {

                orderInfo[index].status = orderInfo[index].status === 2 ? 3 : 2;

                if (orderInfo[index].status == 2) {
                    multiValue.push(value._id)
                } else if (orderInfo[index].status == 3) {
                    multiValue.splice(value._id)
                }


            }
        })


        this.setState({multiValue}, () => {
        })

    }

    handleDelete = (ac, status) => {
        let {userId, userStatus} = this.state
        let self = this

        let obj = {
            'userId': userId,
            'userStatus': userStatus,
            // 'userId': userId,
        }
        this.setState({miniloader: true,})

        let {dispatch} = this.props;
        dispatch(reportsActions.getReasons(obj)).then(data => {

            self.setState({miniloader: false,})
            //
            if (data.statusCode == 1) {
                this.setState({deletePopup: false})
                this.getUser()

            } else {
                this.setState({errors: data.error.responseMessage})
            }
        });


    }
    handleShow = (item) => {
        this.setState({deletePopup: true, userId: item._id, userStatus: 3})
    }
    sendToParent = (e) => {
        this.setState({loader: true})
        this.getUser()
    }

    changeName = (event) => {
        const self = this;
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            search: event.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.sendToParent(self.state.name);
            }, 1000)
        })


    }


    handleSort = sortField => this.setState({
        loader: true,
        sortField,
        sortType: this.state.sortType === 1 ? -1 : 1
    }, () => this.getReasons())


    render() {
        let {deletePopup, reasonListCounts, reasonList, errors, examDateErrorMsg, examDate, examName, examNameErrorMsg, profilePicErrorMsg, excelFile, filterHarvest, farmName, reasonErMsg, orderInfo, count, pageNo, totalCount, dataCount, ItemList, profilePicPreview} = this.state;


        return (
            <>
                <Sidebar/>
                <div className="body_container">
                    <div className="header_search header_search_custom ">
                        <div className="row mb-3">
                            <div className="col-md-7">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="count-list">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <h6 className="mb-2">Total Reasons</h6>
                                                    <h6 className="mb-0">{reasonListCounts}</h6>
                                                </div>
                                                <div>&nbsp;&nbsp;
                                                    <span className="user-icon icon-grey">
                                                            {/*<UserIcon/>*/}
                                                        <i className="fa fa-trash sizeCircleDelete"
                                                           aria-hidden="true"></i>

                                                        </span>
                                                </div>
                                                &nbsp;&nbsp;
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                    </div>
                                    <div className="col-md-4">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                    </div>
                                    <div className="col-md-4">
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 offset-3">
                                <div className="form-group has-search">
                                    <span className="fa fa-search form-control-feedback"></span>
                                    {/*<Form.Control type="Search" placeholder="Search..." name="search" value={"search"}></Form.Control>*/}
                                    <Form.Control type="text" placeholder="Search" name={"search"}
                                                  onChange={this.changeName}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div>
                        <div className="body_inner_wrapper">
                            {this.state.loader ? <Loader/> :
                                (<>
                                    <header className="header_content">
                                        <p>Reasons</p>


                                        <div className="d-flex text-nowrap header_btn">


                                        </div>

                                    </header>


                                    <div className="main_content">
                                        <div class="table-responsive">
                                            <table class="table cm_bottom_space">
                                                <thead>
                                                <tr class="broder_top_0">
                                                    <th scope="col" className="ws-nowrap">S. No.</th>
                                                    <th scope="col" onClick={() => this.handleSort('created')}>Created
                                                        On<i
                                                            className="fas fa-sort text_dark ml-2 d-inline-block"></i>
                                                    </th>

                                                    <th scope="col" onClick={() => this.handleSort('reason')}>Reasons<i
                                                        className="fas fa-sort text_dark ml-2 d-inline-block"></i></th>

                                                </tr>
                                                </thead>
                                                <tbody>

                                                {!_.isEmpty(reasonList) && reasonList.filter(item => item.userStatus !== 3).map((item, i) => (
                                                    <tr key={item._id}>
                                                        <td>{((count * (pageNo - 1)) + (i + 1))}</td>
                                                        <td>{this.ConvertDate(item.created)}</td>
                                                        <td>
                                                            {item.reason == 1 ? "fresh start" : ''}
                                                            {item.reason == 2 ? "met someone" : ''}
                                                            {item.reason == 3 ? "something is broken" : ''}
                                                            {item.reason == 4 ? "need a break" : ''}
                                                            {item.reason == 5 ? "other" : ''}
                                                        </td>
                                                    </tr>))
                                                }


                                                </tbody>


                                            </table>
                                            {reasonList && reasonList.length ?
                                                <div
                                                    className="d-flex justify-content-end cm-pagination">
                                                    <Pagination
                                                        itemClassPrev={'prevClass'}
                                                        itemClassNext={'nextClass'}
                                                        activePage={this.state.pageNo}
                                                        itemsCountPerPage={this.state.count}
                                                        totalItemsCount={this.state.reasonListCounts}
                                                        pageRangeDisplayed={3}
                                                        onChange={this.handlePageChange}
                                                    />
                                                </div> : ''}
                                            {_.isEmpty(this.state.reasonList) ?
                                                <h3 className="text-center">No data found</h3> : ''}
                                            {reasonErMsg ?
                                                <div className="text-center error ">{reasonErMsg}</div> : ''}
                                        </div>


                                    </div>


                                </>)}


                        </div>


                    </div>
                    <Modal aria-labelledby="example-modal-sizes-title-sm "
                           show={this.state.addExamPopup}
                           onHide={this.handleClose}
                           className="cm_order_view order-detailsmodal">
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-sm"><h6>Add Exam</h6></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <form onSubmit={this.handlAddExam}>

                                    <Row>

                                        <Col md="3">
                                            <p>Exam Name:</p>

                                        </Col>
                                        <Col md="9">
                                            <Form.Control type="text"
                                                          onChange={this.handleChanged}
                                                          name="examName"
                                                          value={examName}
                                                          placeholder="Exam Name"/>
                                            {examNameErrorMsg ?
                                                <div
                                                    className="error">{examNameErrorMsg}</div> : null}
                                        </Col>


                                    </Row>

                                    <Row>

                                        <Col md="3">
                                            <p>Exam Date:</p>

                                        </Col>
                                        <Col md="9">
                                            <Form.Control type="text"
                                                          onChange={this.handleChanged}
                                                          name="examDate"
                                                          value={examDate}
                                                          placeholder="Exam Date "/>
                                            {examDateErrorMsg ?
                                                <div
                                                    className="error">{examDateErrorMsg}</div> : null}
                                        </Col>


                                    </Row>


                                    {errors ? errors : ''}
                                    <Row>
                                        <Col md="10" className="text-sm-right">
                                            <Button type="submit"
                                                    variant="warning"
                                                    className="mr-2">Save</Button>
                                            &nbsp;

                                            <Button onClick={this.viewMode}
                                                    variant="dark">Cancel</Button>


                                        </Col>
                                    </Row>


                                </form>

                            </div>
                        </Modal.Body>
                        <Modal.Footer>

                            <div className="footer_btn_wrapper">
                                {/*<Button variant="warning" className="text-light"*/}
                                {/*        onClick={() => this.handleItemAction(ItemList._id, 2)}*/}
                                {/*>*/}
                                {/*    ExamList Fulfilled*/}
                                {/*</Button>*/}

                            </div>
                            <div className="print_button orderabs">
                                {/*<Button onClick={()=>this.redirect(ItemList)} variant="light"><i*/}
                                {/*    className="fas fa-print"></i> Print </Button>*/}


                                <Link className="btn btn-light "><i
                                    className="fas fa-print" title="Invoicddddde"
                                ></i>Print</Link>

                            </div>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={deletePopup} onHide={this.handleClose} className="cm_order_view dlt_item">
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <img src={require('../../assets/img/Delete-Email.png')}/>
                            <br/>
                            <br/>
                            <p>Are you sure you want to delete this user?</p>
                        </Modal.Body>
                        <Modal.Footer>

                            <div className="footer_btn_wrapper">
                                <Button variant="warning" onClick={(e) => this.handleDelete()}>
                                    Delete
                                </Button>
                                {/*<span><i onClick={(e)=>this.handleDelete(e,item)} class="fas fa-trash-alt"></i></span>*/}

                                <Button variant="dark" onClick={this.handleClose}>
                                    Cancel
                                </Button>
                            </div>

                        </Modal.Footer>
                    </Modal>

                </div>
            </>
        )
    }
}

// export default


function mapStateToProps(state) {
    const {props, login, user, mapReducer} = state;
    return {
        props,
        login,
        user,
        mapReducer
    }
}

export default connect(mapStateToProps)(ReasonList);
// export default Settings
