import {reportsConstants} from '../_actions/reports.actions';

const initialState = {};


export function report(state = initialState, action) {
    switch (action.type) {
        case reportsConstants.GET_REASONS_SUCCESS:
            let getReasonsSuccessInfo = action.getReasonsSuccess;
            return getReasonsSuccessInfo

        case reportsConstants.GET_REPORTS_SUCCESS:
            let getReportsSuccessInfo = action.getReportsSuccess;
            return getReportsSuccessInfo



        default:
            return {...state}
    }
}
