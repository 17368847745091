import React, {Component} from 'react'
import {Button, Col, Form, Modal, Row} from 'react-bootstrap'

import {connect} from "react-redux";
import _ from 'lodash';
import Sidebar from '../common/sidebar'
import Pagination from "react-js-pagination";
import {Link} from "react-router-dom";
import moment from "moment-timezone"
import {usersActions} from "../../_actions";
import {UserIcon} from '../Svg'
import {notify} from '../../components/common/Toster'
import Loader from '../../components/common/Loader'


class UserList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            orderInfo: [],
            ItemList: [],
            reId: [],
            excelFile: '',
            examDate: '',
            ItemId: [],
            itemId: [],

            sortField: '',
            sortType: -1,

            reasonErMsg: '',
            show: false,
            farmName: '',
            addExamPopup: false,
            messagePopup: 0, alertPopup: 0, emailPopup: 0,
            lat: 0, long: '',
            count: 10,
            userListCount: 0,

            inactive: 0,
            active: 0,
            totalCount: 0,
            dataCount: 0,
            filterHarvest: '',
            pageNo: 1,
            orderInfo1: [],
            userStatus: 1,
            profilePicPreview: '',

            examName: '',
            userId: '',
            deletePopup: false,
            examNameErrorMsg: '',
            examDateErrorMsg: '',
            userInfo: [],
            // userId: localStorage.getItem('userId'),
            profilePicErrorMsg: '',
            loader: true,
        }

    }

    componentDidMount() {
        this.getUser()

    }


    getUser = () => {
        this.setState({errors: ''});

        let self = this


        let {dispatch} = this.props;
        // dispatch(orderActions.getAllOrderExcel(params)).then(data => {
        let {pageNo, count, sortField, sortType, search} = this.state

        // dispatch(orderActions.getAllOrderExcel(params)).then(data => {
        let params = `?pageNo=${pageNo - 1}&count=${count ? count : 10}&sortField=${sortField ? sortField : ''}&sortType=${sortType ? sortType : ''}&search=${search ? search : ''}`


        dispatch(usersActions.getUser(params)).then(data => {

            this.setState({loader: false})
            let userInfo = data.responseData.userInfo.userInfo;
            let userListCount = data.responseData.userInfo.dataCount;
            let totalCount = data.responseData.userInfo.totalCount;
            let active = data.responseData.userInfo.active;
            let inactive = data.responseData.userInfo.inactive;
            // userCounts: 11
            // active: 11
            // inactive: 0
            // dataCount: 11
            // totalCount: 11
            if (data.statusCode == 1) {
                // let userInfo = data.responseData.userProfile
                // localStorage.setItem('userId', userInfo._id);


                this.setState({
                    userInfo: userInfo,
                    userListCount: userListCount,
                    inactive: inactive,
                    active: active,
                    totalCount: totalCount,


                })
            } else {
                this.setState({errors: data.error.responseMessage})
            }
        });

    }
    handlePageChange = (pageNo) => {

        var self = this;
        this.setState({loader: false, pageNo: pageNo, count: 10}, () => {
            self.getUser();
        });
    }
    handleChanged = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value,
                // emailError: ''
            }
        );
    }

    handleFilter = ele => {

        this.setState({
            loader: false, reasonErMsg: '', filterHarvest: ele, count: 10, pageNo: 1
        }, () => this.getUser())
    }

    handleClose = () => {
        this.setState({addExamPopup: false, deletePopup: false, showInvoice: false,})
    }

    ConvertDate = (created) => {
        let data = moment(created).format("MM/DD/YYYY")
        return data
    }
    ConvertDob = (dob) => {
        // let data =        Date(dob)

        var data = new Date(dob * 1000);
        // let year = data.getFullYear(); // 2020


        let data2 = moment(data).format("MM/DD/YYYY")
        return data2
    }

    handleShowAddExam = (item) => {
        this.setState({addExamPopup: true, ItemList: item})
    }

    handleValidate = () => {
        let validate = true;
        if (this.state.examDate === "" || this.state.examDate === undefined) {
            validate = false;
            this.setState({
                examDateErrorMsg: 'Please enter exam date.'
            })
        } else {
            this.setState({
                examDateErrorMsg: ''
            })
        }
        if (this.state.examName === "" || this.state.examName === undefined) {
            validate = false;
            this.setState({
                examNameErrorMsg: 'Please enter exam name.'
            })
        } else {
            this.setState({
                examNameErrorMsg: ''
            })
        }

        return validate


    }


    handleMaltiSelct = (e, val) => {
        let {orderInfo, multiValue} = this.state;
        let self = this;
        this.setState({reasonErMsg: ''})
        multiValue = typeof this.state.multiValue === 'object' ? this.state.multiValue : []
        let reIdval = []

        if (this.state.reId.includes(val)) {

            for (var i = 0; i < this.state.reId.length; i++) {
                if (this.state.reId[i] === val) {
                    this.state.reId.splice(i, 1);
                }
            }
        } else {
            this.state.reId.push(val)
        }


        this.state.orderInfo.map(function (value, index) {

            if (value._id == val) {

                orderInfo[index].status = orderInfo[index].status === 2 ? 3 : 2;

                if (orderInfo[index].status == 2) {
                    multiValue.push(value._id)
                } else if (orderInfo[index].status == 3) {
                    multiValue.splice(value._id)
                }


            }
        })


        this.setState({multiValue}, () => {
        })

    }
    handlAddExam = (e) => {
        e.preventDefault()
        let {examDate, examName} = this.state;
        let params = {
            'examDate': examDate,
            'examName': examName
        }
        if (this.handleValidate()) {

            let self = this;

            let {dispatch} = this.props;
            self.setState({loader: true})
            // self.setState({loader: false, show: false})

            dispatch(usersActions.addExam(params)).then(data => {

                if (data.statusCode == 1) {
                    self.setState({
                        loader: false,
                        reId: [],
                        addExamPopup: false,
                        reasonErMsg: '',
                        examName: '',
                        examNameErrorMsg: '',
                        examDate: '',
                        examDateErrorMsg: '',
                    })

                } else {
                    self.setState({loader: false})
                    self.setState({reId: [], multiValue: '', reasonErMsg: '', errors: data.error.responseMessage})
                }
            });

        } else {


        }
    }

    handleStatus = (e,item,) => {
        let {userId, userStatus} = this.state
        let self = this

        let obj = {
            'usrId': item._id,
            'userStatus': e.target.value,
            // 'userId': userId,
        }
        this.setState({loader: true,})

        let {dispatch} = this.props;
        dispatch(usersActions.changeUserStatus(obj)).then(data => {

            self.setState({loader: false,})
            //
            if (data.statusCode == 1) {
                // this.setState({deletePopup: false})
                this.getUser()

            } else {
                this.setState({errors: data.error.responseMessage})
            }
        });


    }
    
    changePasswordOfUser = (e,item) => {
        let userId = item._id
        var self = this

        this.setState({loader: true,})

        let obj = new URLSearchParams()
        obj.append('userId', userId);
        //obj.append('newPassword', '123456');
        
        let {dispatch} = this.props;
        dispatch(usersActions.changeUserPassword(obj)).then(data => {
            self.setState({loader: false})
            if (data.statusCode == 1) {
                self.setState({
                    loader: false,
                })
                notify('success', data.responseData.message)

            } else {
                notify('error', data.responseData.message)
                self.setState({errors: data.responseData.message})
            }
        })


    }
    handleDeleteDialog = (e,item)=>{
        this.setState({deletePopup: true, userId: item._id})

    }
    handleDelete = (e) => {
        let {userId} = this.state

        
        let params = `?userId=${userId}`

        this.setState({loader: true})

        let {dispatch} = this.props;
        dispatch(usersActions.deleteUser(params)).then(data => {

            
            if (data.statusCode == 1) {
                notify('success', data.responseData.message)
                this.getUser()

            } else {
                notify('error', data.responseData.message)
                this.setState({errors: data.responseData.message})
            }
            this.setState({loader: false})
            this.setState({deletePopup: false})
            
        });


    }
    handleShow = (item) => {
        this.setState({deletePopup: true, userId: item._id, userStatus: 3})
    }
    sendToParent = (e) => {
        this.setState({loader: true})
        this.getUser()
    }

    changeName = (event) => {
        const self = this;
        if (self.state.typingTimeout) {
            clearTimeout(self.state.typingTimeout);
        }

        self.setState({
            search: event.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
                self.sendToParent(self.state.name);
            }, 1000)
        })


    }


    handleSort = sortField => this.setState({
        loader: true,
        sortField,
        sortType: this.state.sortType === 1 ? -1 : 1
    }, () => this.getUser())


    render() {
        let {deletePopup, userStatus,userInfo, inactive, active, errors, examDateErrorMsg, examDate, examName, examNameErrorMsg, profilePicErrorMsg, excelFile, filterHarvest, farmName, reasonErMsg, orderInfo, count, pageNo, totalCount, dataCount, ItemList, profilePicPreview} = this.state;


        return (
            <>
                <Sidebar/>
                <div className="body_container">
                    <div className="header_search header_search_custom ">
                        <div className="row mb-3">
                            <div className="col-md-7">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="count-list">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <h6 className="mb-2">Total Users</h6>
                                                    <h6 className="mb-0">{totalCount}</h6>
                                                </div>
                                                <div>
                                                        <span className="user-icon icon-blue">
                                                            <UserIcon/>
                                                        </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="count-list">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <h6 className="mb-2">Active Users</h6>
                                                    <h6 className="mb-0">{active}</h6>

                                                </div>
                                                <div>
                                                        <span className="user-icon icon-pink">
                                                            <UserIcon/>
                                                        </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="count-list">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div>
                                                    <h6 className="mb-2">Inactive Users</h6>
                                                    <h6 className="mb-0">{inactive}</h6>

                                                </div>
                                                <div>
                                                        <span className=" user-icon icon-black">
                                                            <UserIcon/>
                                                        </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 offset-3">
                                <div className="form-group has-search">
                                    <span className="fa fa-search form-control-feedback"></span>
                                    {/*<Form.Control type="Search" placeholder="Search..." name="search" value={"search"}></Form.Control>*/}
                                    <Form.Control type="text" placeholder="Search" name={"search"}
                                                  onChange={this.changeName}
                                        // onKey/Down={this.handleKeyDown}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="body_inner_wrapper">
                            {this.state.loader ? <Loader/> :
                                (<>
                                    <header className="header_content">
                                        <p>Users</p>


                                        <div className="d-flex text-nowrap header_btn">


                                        </div>

                                    </header>


                                    <div className="main_content">
                                        <div class="table-responsive">
                                            <table class="table cm_bottom_space">
                                                <thead>
                                                <tr class="broder_top_0">
                                                    {/*<th>Change Status</th>*/}
                                                    <th scope="col" className="ws-nowrap">S. No.</th>
                                                    <th scope="col" onClick={() => this.handleSort('created')}>Created
                                                        On<i
                                                            className="fas fa-sort text_dark ml-2 d-inline-block"></i>
                                                    </th>
                                                    <th scope="col" onClick={() => this.handleSort('firstName')}>Name<i
                                                        className="fas fa-sort text_dark ml-2 d-inline-block"></i></th>
                                                    <th scope="col" onClick={() => this.handleSort('mobile')}>Mobile
                                                        Number<i
                                                            className="fas fa-sort text_dark ml-2 d-inline-block"></i>
                                                    </th>
                                                    <th scope="col" onClick={() => this.handleSort('email')}>Email<i
                                                        className="fas fa-sort text_dark ml-2 d-inline-block"></i></th>
                                                    {/*<th scope="col" className="ws-nowrap"> Name</th>*/}
                                                    {/*<th scope="col" className="ws-nowrap">  Email</th>*/}
                                                    <th scope="col" className="ws-nowrap">Dob</th>
                                                    <th scope="col" className="ws-nowrap">Profic Pic</th>
                                                    <th scope="col" className="ws-nowrap">Status</th>
                                                    <th scope="col" className="ws-nowrap">Claimed Status</th>
                                                    <th scope="col">Dishes</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>

                                                {!_.isEmpty(userInfo) && userInfo.filter(item => item.userStatus !== 3).map((item, i) => (
                                                    <tr key={item._id}>
                                                        <td>{((count * (pageNo - 1)) + (i + 1))}</td>
                                                        <td>{this.ConvertDate(item.created)}</td>
                                                        <td><Link
                                                            to={`user-info/${item._id}`}>{item.firstName} {item.lastName}</Link>
                                                        </td>
                                                        <td>{item.mobile ? "+" : ''}{item.countryCode} {item.mobile}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.dob ? this.ConvertDob(item.dob) : ''}</td>
                                                        <td>{item.profileCover?<img height="40" width="40" src={item.profileCover}/>:''}</td>
                                                        <td>
                                                            <select value={item.userStatus}
                                                                    name="userStatus"
                                                                    onChange={(e)=>this.handleStatus(e,item)}
                                                                    className="form-control">
                                                                <option value=''>Select</option>
                                                                <option value='1'>Active</option>
                                                                <option value='2'>Inactive</option>

                                                            </select>
                                                            {/*{item.userStatus == 1 ? "Active" : ''}*/}
                                                        </td>
                                                        <td>
                                                            {item.claimedStatus == 1 ? "Claimed" : 'Unclaimed'}
                                                        </td>
                                                        {/*<td>*/}

                                                        {/*    <Link*/}
                                                        {/*        to={`dish/${item._id}`}*/}

                                                        {/*>Check User Dishes</Link> </td>*/}
                                                        <td>

                                                            <Link
                                                                to={`user-dish/${item._id}`}

                                                            >Check User Dishes</Link></td>
                                                            <td>
                                                            <div class="text-info user_actions"><i onClick={(e)=>this.handleDeleteDialog(e,item)} class="fas fa-trash-alt"></i></div>

                                                            </td>
                                                            <td>
                                                            <div class="text-info user_actions" onClick={(e)=>this.changePasswordOfUser(e,item)}><i  class="fas fa-lock"></i></div>

                                                            </td>

                                                    </tr>))
                                                }


                                                </tbody>


                                            </table>

                                            {userInfo && userInfo.length ?
                                                <div
                                                    className="d-flex justify-content-end cm-pagination">
                                                    {/*{this.state.userListCount}*/}
                                                    <Pagination
                                                        itemClassPrev={'prevClass'}
                                                        itemClassNext={'nextClass'}
                                                        activePage={this.state.pageNo}
                                                        itemsCountPerPage={this.state.count}
                                                        totalItemsCount={this.state.userListCount}
                                                        pageRangeDisplayed={3}
                                                        onChange={this.handlePageChange}
                                                    />
                                                </div> : ''}
                                            {_.isEmpty(this.state.userInfo) ?
                                                <h3 className="text-center">No data found</h3> : ''}
                                            {reasonErMsg ?
                                                <div className="text-center error ">{reasonErMsg}</div> : ''}
                                        </div>


                                    </div>


                                </>)}


                        </div>


                    </div>
                    <Modal aria-labelledby="example-modal-sizes-title-sm "
                           show={this.state.addExamPopup}
                           onHide={this.handleClose}
                           className="cm_order_view order-detailsmodal">
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-sm"><h6>Add Exam</h6></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <form onSubmit={this.handlAddExam}>
                                    {/*<div className="">*/}

                                    {/*    {profilePicPreview ?*/}
                                    {/*        <img src={profilePicPreview}/> :*/}
                                    {/*        <span className="profile_icon_Admin ">*/}
                                    {/*                                                <img*/}
                                    {/*                                                    src={require('../../assets/img/logo.png')}/></span>}*/}

                                    {/*    <span className="img_icon">*/}
                                    {/*                                            <span className="file_input">*/}
                                    {/*                                                <input type="file"*/}
                                    {/*                                                       className="cm_hide_input"*/}
                                    {/*                                                       accept="image/x-png,image/gif,image/jpeg,image/jpg"*/}
                                    {/*                                                       onChange={this.handlePicUpload}/>*/}
                                    {/*                                            </span>*/}
                                    {/*                                            <img*/}
                                    {/*                                                src={require('../../assets/img/imgicon.png')}/>*/}
                                    {/*                                        </span>*/}

                                    {/*</div>*/}
                                    {/*{profilePicErrorMsg ?*/}
                                    {/*    <div*/}
                                    {/*        className="error text-center ">{profilePicErrorMsg}</div> : null}*/}
                                    {/*{profilePicErrorMsg ? <br/> : ''}*/}


                                    <Row>

                                        <Col md="3">
                                            <p>Exam Name:</p>

                                        </Col>
                                        <Col md="9">
                                            <Form.Control type="text"
                                                          onChange={this.handleChanged}
                                                          name="examName"
                                                          value={examName}
                                                          placeholder="Exam Name"/>
                                            {examNameErrorMsg ?
                                                <div
                                                    className="error">{examNameErrorMsg}</div> : null}
                                        </Col>


                                    </Row>

                                    <Row>

                                        <Col md="3">
                                            <p>Exam Date:</p>

                                        </Col>
                                        <Col md="9">
                                            <Form.Control type="text"
                                                          onChange={this.handleChanged}
                                                          name="examDate"
                                                          value={examDate}
                                                          placeholder="Exam Date "/>
                                            {examDateErrorMsg ?
                                                <div
                                                    className="error">{examDateErrorMsg}</div> : null}
                                        </Col>


                                    </Row>


                                    {errors ? errors : ''}
                                    <Row>
                                        <Col md="10" className="text-sm-right">
                                            <Button type="submit"
                                                    variant="warning"
                                                    className="mr-2">Save</Button>
                                            &nbsp;

                                            <Button onClick={this.viewMode}
                                                    variant="dark">Cancel</Button>


                                        </Col>
                                    </Row>


                                </form>

                            </div>
                        </Modal.Body>
                        <Modal.Footer>

                            <div className="footer_btn_wrapper">
                                {/*<Button variant="warning" className="text-light"*/}
                                {/*        onClick={() => this.handleItemAction(ItemList._id, 2)}*/}
                                {/*>*/}
                                {/*    ExamList Fulfilled*/}
                                {/*</Button>*/}

                            </div>
                            <div className="print_button orderabs">
                                {/*<Button onClick={()=>this.redirect(ItemList)} variant="light"><i*/}
                                {/*    className="fas fa-print"></i> Print </Button>*/}


                                <Link className="btn btn-light "><i
                                    className="fas fa-print" title="Invoicddddde"
                                ></i>Print</Link>

                            </div>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={deletePopup} onHide={this.handleClose} className="cm_order_view dlt_item">
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <img src={require('../../assets/img/Delete-Email.png')}/>
                            <br/>
                            <br/>
                            <p>Are you sure you want to delete this user?</p>
                        </Modal.Body>
                        <Modal.Footer>

                            <div className="footer_btn_wrapper">
                                <Button variant="warning" onClick={(e) => this.handleDelete()}>
                                    Delete
                                </Button>
                                {/*<span><i onClick={(e)=>this.handleDelete(e,item)} class="fas fa-trash-alt"></i></span>*/}

                                <Button variant="dark" onClick={this.handleClose}>
                                    Cancel
                                </Button>
                            </div>

                        </Modal.Footer>
                    </Modal>

                </div>
            </>
        )
    }
}

// export default


function mapStateToProps(state) {
    const {props, login, user, mapReducer} = state;
    return {
        props,
        login,
        user,
        mapReducer
    }
}

export default connect(mapStateToProps)(UserList);
// export default Settings
