import React, { Component } from 'react'
import { Button, Card, CardDeck, Col, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap'
import { usersActions } from "../../_actions";
import { connect } from "react-redux";
import _ from 'lodash';
import Sidebar from '../../components/common/sidebar'
// import * as jsPDF from 'jspdf';
import { Link } from "react-router-dom";
import {notify} from '../../components/common/Toster'

import Loader from '../../components/common/Loader'


const Units = type => {

    switch (type) {


        // case 1:
        //     return 'wreath';
        //     break;
        // case 2:
        //     return 'bouquet';
        //     break;
        case 3:
            return 'bunch'
            break;
        // case 4:
        //     return 'lb';
        //     break;
        case 5:
            return 'pint';
            break;
        case 6:
            return 'head'
            break;

        case 7:
            return 'bag ';
            break;
        case 8:
            return 'half pint';
            break;
        // case 9:
        //     return '1/2 lb bag'
        //     break;
        case 10:
            return 'Pound'
            break;

        case 11:
            return 'Half Pound Bag'
            break;

        case 12:
            return 'Half Pound'
            break;
        case 13:
            return 'others'
            break;


        default:
            return ''
            break;

    }


}


class UserDishes extends Component {

    constructor(props) {
        super(props);


        this.state = {
            view:1,
            errors: '',
            success: '',
            userInfo: [],
            isEdit: false,
            farmImagePreview: '', farmImageErrorMsg: '', farmMediaErrorMsg: '', farmImage: [],
            farmLogoPreview: '', farmLogo: '', farmLogoErrorMsg: '',
            farmVideoPreview: '', farmVideo: '', farmVideoErrorMsg: '',
            userId: localStorage.getItem('userId'),
            farmName: '', farmStatus: '', shortInfo: '', infoAboutFarm: '', infoAboutFarmer: '', description: '',
            farmId: '',
            userProfile: [],
            dishedYou: [],
            youDished: [],
            farmImage1Preview: [],
            media: [],
            valiDate: true, loader: false,
            inventoryType:1,
            pageNo:1,
            count:0,
            address: '',
            addressLine1: '',
            addressLine1ErrorMsg: '',
            addressLine2: '',
            state: '',
            postalCode: '',
            city: '',
            lat: 0,
            lng: 0,
            deletePopup: false,

        }

    }

    componentDidMount() {
        this.getAllDishes()
        this.getUserDetail()

    }


    getAllDishes = () => {
        let {userId, farmLogo, farmImage, farmVideo, farmLogoPreview, farmVideoPreview, farmName, farmStatus, infoAboutFarm, infoAboutFarmer, description} = this.state;
        this.setState({errors: ''});

        let self = this
        this.setState({loader: true});
// let userId=
        let params = `?userId=${this.props.match.params.id}`

        let {dispatch} = this.props;
        dispatch(usersActions.getAllDishes(params)).then(data => {
            if (data.statusCode==1) {
                let response = data.responseData.response
                self.setState({loader: false})

                if (data.statusCode == 1) {

                    self.setState({
                        response: response,
                        dishedYou: response.dishedYou,
                        youDished: response.youDished,

                    })
                } else {
                    self.setState({errors: data.error.responseMessage})
                }
            }
            else {
                    self.setState({errors: data.error.responseMessage})
                }


        });

    }
    getUserDetail= () => {
        let {userId, farmLogo, farmImage, farmVideo, farmLogoPreview, farmVideoPreview, farmName, farmStatus, infoAboutFarm, infoAboutFarmer, description} = this.state;
        this.setState({errors: ''});

        let self = this
        this.setState({loader: true});
// let userId=
        let params = `?userId=${this.props.match.params.id}`

        let {dispatch} = this.props;
        dispatch(usersActions.getUserDetail(params)).then(data => {
            if (!_.isEmpty(data)) {
                let userProfile = data.responseData.userProfile
                self.setState({loader: false})

                if (data.statusCode == 1) {

                    self.setState({
                        userProfile:  userProfile


                    })
                } else {
                    self.setState({errors: data.error.responseMessage})
                }
            }

        });

    }


    editMode = () => {
        this.setState({ isEdit: true });
    }

    viewMode = () => {
        this.setState({ isEdit: false });
    }
    inventoryType = (e) => {


        if (e == '1') {
            this.setState({ inventoryType: 1 }, () => this.getAllDishes());
        } else if (e == '2')
        {
            this.setState({ inventoryType: 2 }, () => this.getAllDishes());
        }
    }

    handleClose = () => {
        this.setState({deletePopup: false})
    }

    handleDeleteDialog = (e,item)=>{
        this.setState({deletePopup: true, userId: item.userId})

    }

    handleDelete = (e,) => {
        let {userId} = this.state

        let params = `?userId=${userId}`

        this.setState({loader: true})

        let {dispatch} = this.props;
        dispatch(usersActions.deleteUserDish(params)).then(data => {
            
            if (data.statusCode == 1) {
                notify('success', data.responseData.message)
                this.getAllDishes()

            } else {
                notify('error', data.responseData.message)
                this.setState({errors: data.responseData.message})
            }
            this.setState({loader: false})
            this.setState({deletePopup: false})
        });


    }


    render() {
        let {
            youDished, dishedYou,view,pageNo,count,
            inventoryType,deletePopup,
        } = this.state;


        return (

            <>
                <Sidebar />

                <div>
                    <div className="body_container">
                        <div className="body_inner_wrapper">

                            {this.state.loader ? <Loader/> :
                                (<>
                                    <header className="header_content">
                                        <p>Dishes</p>



                                    </header>

                                    <div className="main_content cm-inventory">

                                        {/*{view === 1 ?*/}
                                            <div className="cm-inventory">
                                                <Tabs defaultActiveKey='1' onSelect={(e) => this.inventoryType(e)}
                                                      activeKey={inventoryType}
                                                      id="uncontrolled-tab-example">
                                                    <Tab eventKey='1' title="You Dished">

                                                        <div className="cm-inventory_gridview">
                                                            <div class="table-responsive" id="divToPrint">
                                                                <table class="table cm_bottom_space">
                                                                    <thead>


                                                                    <tr class="broder_top_0">



                                                                        <th scope="col">S.No.</th>
                                                                        <th scope="col">Profile Pic</th>
                                                                        <th scope="col">Name</th>
                                                                        <th scope="col">Email</th>
                                                                        {/*<th scope="col">About me</th>*/}
                                                                        <th scope="col">Gender</th>
                                                                        {/*<th scope="col"></th>*/}
                                                                        <th scope="col">Avg. Rating</th>
                                                                        {/*<th scope="col">No. of User Dishes</th>*/}
                                                                        <th scope="col">Dish Attractive</th>
                                                                        <th scope="col">Dish Likable</th>
                                                                        <th scope="col">Dish Trustworthy</th>
                                                                        <th scope="col">Action</th>

                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    
                                                                    {!_.isEmpty(youDished) && youDished.map((value, i) => (
                                                                        <tr key={value._id}>

                                                                            <th>{((pageNo - 1) * count + ((i + 1)))}</th>

                                                                            <td><img height="20px" width="20px"
                                                                                     src={value.profileCover} /></td>
                                                                            <td>
                                                                                <Link
                                                                                    to={`/user-info/${value.userId}`}>{value.firstName} {value.lastName}</Link>
                                                                            </td>

                                                                            <td>{value ? value.email : ''}</td>
                                                                            {/*<td>{value ? value.aboutMe : ''}</td>*/}
                                                                            <td>
                                                                                {(value.gender == 0 ? "Male" : '')}
                                                                                {(value.gender == 1 ? "Male" : '')}
                                                                                {(value.gender == 2 ? "Female" : '')}
                                                                                {(value.gender == 3 ? "Others" : '')}</td>
                                                                            {/*<td> {value.claimedStatus==1?"Claimed":"unclaimed"}</td>*/}
                                                                            <td>   {value.dishes.dish}</td>
                                                                            {/*<td>  {value.totalRatedUser}</td>*/}
                                                                            <td>  {value.dishes.dishAttractive}</td>
                                                                            <td>  {value.dishes.dishLikable}</td>
                                                                            <td>  {value.dishes.dishTrustworthy}</td>
                                                                            <td>
                                                                                <div class="text-info"><i  class="fas fa-trash-alt" onClick={(e)=>this.handleDeleteDialog(e,value)}></i></div>

                                                                            </td> 


                                                                        </tr>))
                                                                    }                                   </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </Tab>


                                                    <Tab eventKey="2" title="Dished You">
                                                        <div className="cm-inventory_gridview">
                                                            <div className="table-responsive">
                                                                <table className="table cm_bottom_space">
                                                                    <thead>


                                                                    <tr className="broder_top_0">
                                                                        <th scope="col">S.No.</th>
                                                                        <th scope="col">Profile pic</th>
                                                                        <th scope="col">Name</th>
                                                                        <th scope="col">Email</th>
                                                                        {/*<th scope="col">About me</th>*/}
                                                                        <th scope="col">Gender</th>
                                                                        {/*<th scope="col">claimed status</th>*/}
                                                                        <th scope="col">Total dishes</th>
                                                                        {/*<th scope="col">No. of User Dishes</th>*/}
                                                                        <th scope="col">Dish Attractive</th>
                                                                        <th scope="col">Dish Likable</th>
                                                                        <th scope="col">Dish Trustworthy</th>
                                                                        <th scope="col">Action</th>

                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                    {!_.isEmpty(dishedYou) && dishedYou.map((value, i) => (
                                                                        <tr key={value._id}>

                                                                            <th>{((i + 1) + ((pageNo - 1) * count))}</th>
                                                                            <td>

                                                                                <img height="20px" width="20px"
                                                                                                                   src={value.profileCover} />
                                                                            </td>
                                                                            <td>
                                                                                <Link
                                                                                    to={`/user-info/${value.userId}`}>{value.firstName} {value.lastName}</Link>
                                                                            </td>

                                                                            <td><span
                                                                                className="text-info">{value ? value.email : ''}</span>
                                                                            </td>

                                                                            {/*<td>{value ? value.aboutMe : ''}</td>*/}
                                                                            <td>
                                                                                {(value.gender == 0 ? "Male" : '')}
                                                                                {(value.gender == 1 ? "Male" : '')}
                                                                                {(value.gender == 2 ? "Female" : '')}
                                                                                {(value.gender == 3 ? "Others" : '')}</td>
                                                                            {/*<td> {value.claimedStatus==1?"Claimed":"unclaimed"}</td>*/}
                                                                            <td>   {value.dishes.dish}</td>
                                                                            {/*<td>  {value.totalRatedUser}</td>*/}
                                                                            <td>  {value.dishes.dishAttractive}</td>
                                                                            <td>  {value.dishes.dishLikable}</td>
                                                                            <td>  {value.dishes.dishTrustworthy}</td>
                                                                            <td>
                                                                                <div class="text-info"><i  class="fas fa-trash-alt" onClick={(e)=>this.handleDeleteDialog(e,value)}></i></div>

                                                                            </td> 


                                                                        </tr>))
                                                                    }                                   </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </Tab>

                                                </Tabs>
                                            </div>



                                        {/*    :*/}


                                        {/*    <Tabs defaultActiveKey='1' onSelect={(e) => this.inventoryType(e)}*/}
                                        {/*          activeKey={inventoryType}*/}
                                        {/*          id="uncontrolled-tab-example">*/}
                                        {/*        <Tab eventKey='1' title="You Dished">*/}

                                        {/*            <div className="cm-inventory_gridview">*/}
                                        {/*                <div class="table-responsive" id="divToPrint">*/}
                                        {/*                    <table class="table cm_bottom_space">*/}
                                        {/*                        <thead>*/}


                                        {/*                        <tr class="broder_top_0">*/}



                                        {/*                            <th scope="col">S.No.</th>*/}
                                        {/*                            <th scope="col">Profile Pic</th>*/}
                                        {/*                            <th scope="col">Name</th>*/}
                                        {/*                            <th scope="col">Email</th>*/}
                                        {/*                            /!*<th scope="col">About mew</th>*!/*/}
                                        {/*                            <th scope="col">Gender</th>*/}
                                        {/*                            /!*<th scope="col">claimed status</th>*!/*/}
                                        {/*                            <th scope="col">Total dishes</th>*/}
                                        {/*                            /!*<th scope="col">No. of User Dishes</th>*!/*/}
                                        {/*                            <th scope="col">Dish Attractive</th>*/}
                                        {/*                            <th scope="col">Dish Likable</th>*/}
                                        {/*                            <th scope="col">Dish Trustworthy</th>*/}

                                        {/*                        </tr>*/}
                                        {/*                        </thead>*/}
                                        {/*                        <tbody>*/}

                                        {/*                        {!_.isEmpty(youDished) && youDished.map((value, i) => (*/}
                                        {/*                            <tr key={value._id}>*/}

                                        {/*                                <th>{((pageNo - 1) * count + ((i + 1)))}</th>*/}

                                        {/*                                <td><img height="20px" width="20px"*/}
                                        {/*                                         src={value.profileCover} /></td>*/}
                                        {/*                                <td>*/}
                                        {/*                                    <Link*/}
                                        {/*                                        to={`/user-info/${value.userId}`}>{value.firstName} {value.lastName}</Link>*/}
                                        {/*                                </td>*/}

                                        {/*                                <td>{value ? value.email : ''}</td>*/}
                                        {/*                                /!*<td>{value ? value.aboutMe : ''}</td>*!/*/}
                                        {/*                                <td>*/}
                                        {/*                                    {(value.gender == 0 ? "Male" : '')}*/}
                                        {/*                                    {(value.gender == 1 ? "Male" : '')}*/}
                                        {/*                                    {(value.gender == 2 ? "Female" : '')}*/}
                                        {/*                                    {(value.gender == 3 ? "Others" : '')}</td>*/}
                                        {/*                                /!*<td> {value.claimedStatus==1?"Claimed":"unclaimed"}</td>*!/*/}
                                        {/*                                <td>   {value.dishes.dish}</td>*/}
                                        {/*                                /!*<td>  {value.totalRatedUser}</td>*!/*/}
                                        {/*                                <td>  {value.dishes.dishAttractive}</td>*/}
                                        {/*                                <td>  {value.dishes.dishLikable}</td>*/}
                                        {/*                                <td>  {value.dishes.dishTrustworthy}</td>*/}


                                        {/*                            </tr>))*/}
                                        {/*                        }                                   </tbody>*/}
                                        {/*                    </table>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        </Tab>*/}


                                        {/*        <Tab eventKey="2" title="Dished You">*/}
                                        {/*            <div className="cm-inventory_gridview">*/}
                                        {/*                <div className="table-responsive">*/}
                                        {/*                    <table className="table cm_bottom_space">*/}
                                        {/*                        <thead>*/}


                                        {/*                        <tr className="broder_top_0">*/}
                                        {/*                            <th scope="col">S.No.</th>*/}
                                        {/*                            <th scope="col">Profile pic</th>*/}
                                        {/*                            <th scope="col">Name</th>*/}
                                        {/*                            <th scope="col">Email</th>*/}
                                        {/*                            /!*<th scope="col">About me</th>*!/*/}
                                        {/*                            <th scope="col">Gender</th>*/}
                                        {/*                            /!*<th scope="col">claimed status</th>*!/*/}
                                        {/*                            <th scope="col">Total dishes</th>*/}
                                        {/*                            /!*<th scope="col">No. of User Dishes</th>*!/*/}
                                        {/*                            <th scope="col">Dish Attractive</th>*/}
                                        {/*                            <th scope="col">Dish Likable</th>*/}
                                        {/*                            <th scope="col">Dish Trustworthy</th>*/}

                                        {/*                        </tr>*/}
                                        {/*                        </thead>*/}
                                        {/*                        <tbody>*/}
                                        {/*                        {*/}
                                        {/*                        }*/}
                                        {/*                        {!_.isEmpty(dishedYou) && dishedYou.map((value, i) => (*/}
                                        {/*                            <tr key={value._id}>*/}

                                        {/*                                <th>{((i + 1) + ((pageNo - 1) * count))}</th>*/}
                                        {/*                                <td>*/}

                                        {/*                                    <img height="20px" width="20px"*/}
                                        {/*                                         src={value.profileCover} />*/}
                                        {/*                                </td>*/}
                                        {/*                                <td>*/}
                                        {/*                                    <Link*/}
                                        {/*                                        to={`/user-info/${value.userId}`}>{value.firstName} {value.lastName}</Link>*/}
                                        {/*                                </td>*/}

                                        {/*                                <td><span*/}
                                        {/*                                    className="text-info">{value ? value.email : ''}</span>*/}
                                        {/*                                </td>*/}

                                        {/*                                /!*<td>{value ? value.aboutMe : ''}</td>*!/*/}
                                        {/*                                <td>*/}
                                        {/*                                    {(value.gender == 0 ? "Male" : '')}*/}
                                        {/*                                    {(value.gender == 1 ? "Male" : '')}*/}
                                        {/*                                    {(value.gender == 2 ? "Female" : '')}*/}
                                        {/*                                    {(value.gender == 3 ? "Others" : '')}</td>*/}
                                        {/*                                /!*<td> {value.claimedStatus==1?"Claimed":"unclaimed"}</td>*!/*/}
                                        {/*                                <td>   {value.dishes.dish}</td>*/}
                                        {/*                                /!*<td>  {value.totalRatedUser}</td>*!/*/}
                                        {/*                                <td>  {value.dishes.dishAttractive}</td>*/}
                                        {/*                                <td>  {value.dishes.dishLikable}</td>*/}
                                        {/*                                <td>  {value.dishes.dishTrustworthy}</td>*/}


                                        {/*                            </tr>))*/}
                                        {/*                        }                                   </tbody>*/}
                                        {/*                    </table>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        </Tab>*/}


                                        {/*    </Tabs>*/}
                                        {/*}*/}
                                        {  inventoryType==1?
                                        (_.isEmpty(youDished) ? <h3 className="text-center">No data found</h3> : ''):
                                        (_.isEmpty(dishedYou) ? <h3 className="text-center">No data found</h3> : '')}

                                    </div>
                                </>)}
                        </div>








                    </div>
                    <Modal show={deletePopup} onHide={this.handleClose} className="cm_order_view dlt_item">
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <img src={require('../../assets/img/Delete-Email.png')}/>
                            <br/>
                            <br/>
                            <p>Are you sure you want to delete this dish?</p>
                        </Modal.Body>
                        <Modal.Footer>

                            <div className="footer_btn_wrapper">
                                <Button variant="warning" onClick={(e) => this.handleDelete()}>
                                    Delete
                                </Button>
                                {/*<span><i onClick={(e)=>this.handleDelete(e,item)} class="fas fa-trash-alt"></i></span>*/}

                                <Button variant="dark" onClick={this.handleClose}>
                                    Cancel
                                </Button>
                            </div>

                        </Modal.Footer>
                    </Modal>
                </div>
            </>)
    }

}



function mapStateToProps(state) {
    const {props, login, user, mapFarmReducer, farm} = state;

    return {
        props,
        login,
        user, mapFarmReducer,
        farm
    }
}
export default connect(mapStateToProps)(UserDishes);

