// import React from 'react'
import React, {Component} from 'react';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import {Container, Navbar, Row, Col, Form, Button} from 'react-bootstrap'
import {usersActions} from "../../_actions";

import { isValidEmail } from '../../utils/Validation'
import Loader from '../common/Loader'
import {notify} from '../common/Toster'
import axios from "axios";
import {connect} from "react-redux";
// import { _Api } from '../services/Api';
// import Url from '../services/BaseUrl'

class ForgotPassword extends Component {

    constructor(props) {
        super(props);


        this.state = {

            email: '',
            errors:'',
            responseError:'',
            err:''


        }
        
    }

  


    handleOnChange = (e) => {
        this.setState({ [e.target.name]: e.target.value, errors: [], responseError: '' })
    }


    handleValidate = () => {
        let validate = false;
        let errors = {};
        let { email, desc, imageLocation, phone, countryCode } = this.state

        if (_.isEmpty(email)) {
            validate = true;
            this.setState({errors:'Email is required.'})
            // errors['email'] = 'email is required.';
        } else if (!isValidEmail(email)) {
            validate = true;
            // errors['email'] = 'Enter a valid email.';
            this.setState({errors:'Enter a valid email.'})

        }
      

        // this.setState({ errors });
        return validate;
    }

   // Url.ADMIN_GET_PROFILE_TYPE.method, Url.ADMIN_GET_PROFILE_TYPE.url, "",
   handleForgotSubmit = (e) => {
    e.preventDefault();
    if (!this.handleValidate()) {
        let { email } = this.state;
        let obj = {
            email: email,
            
        }
        let self=this;
        let {dispatch} = this.props;
        dispatch(usersActions.forgot(obj)).then(data => {


            this.setState({loader: false})
            let getData = this.props.user;

            if (data.statusCode == 1) {
                this.setState({success:data.responseData.message})
                // let userInfo = data.data.responseData.userProfile;
                // axios.defaults.headers.common['accessToken'] = `${data.data.responseData.accessToken}`;

                // localStorage.setItem('accessToken', data.data.responseData.accessToken);

                // self.props.history.push('/users');
            } else {
                this.setState({errors: data.error.responseMessage})
                // self.props.history.push('/');
            }
        });
        // _Api(Url.SUPER_ADMIN_FORGOT_PASSWORD.method, Url.SUPER_ADMIN_FORGOT_PASSWORD.url, req)
        //         .then((resp) => {
        //             if(resp.statusCode==1){
        //                 notify('success', resp.responseData.message)
        //                 this.props.history.goBack()
        //             }
        //                 // this.setState({ driverInfo: resp.responseData.driverInfo, isLoader: false })
        //             // notify('success', resp.responseData.message)
        //             // this.props.history.goBack()
        //             // this.closeModal()
        //         }).catch(err=>notify('error', err.error.responseMessage) )
    }
}

    

//sign in
    // handleForgotSubmit = e => {
    //     let { email,long, lat,password,userType} = this.state;
    //     this.setState({redirect: false, errors: ''});
    //     e.preventDefault();

    //     let self=this
    //     if (this.handleValidation()) {
    //         this.setState({redirect: false,loader: true});

            // let obj = {
            //     'email': email.toLowerCase(),
            // }

            // let {dispatch} = this.props;
            // dispatch(usersActions.forgot(obj)).then(data => {


            //     this.setState({loader: false})
                // let getData = this.props.user;

    //             if (data.data.statusCode == 1) {
    //                 let userInfo = data.data.responseData.userProfile
    //                 this.setState({success: data.data.responseData.message,errors:''})

    //                 // self.props.history.push('/order');
    //             }
    //             else {
    //                 this.setState({errors: data.data.error.responseMessage})
    //             }
    //         });
    //     }
    // }

 

    goBack = () => {
        this.props.history.goBack()
        // this.setState({ rs:'',success:'',firstNameErrorMsg:'', errors:'',emailErrorMsg:'',passwordErrorMsg:'',confirmPasswordErrorMsg:'', })
    }

    render() {
        let {email,password,passwordErrorMsg,emailErrorMsg,errors,success}=this.state;
        return (

            <div className="body_container_wrapper login_page">
                <Container>
                    {/*<h1>*/}
                    {/*    <Navbar.Brand href="#home">*/}
                    {/*        <img src={require('../../assets/img/datedish logo .png')} alt="Logo" />*/}
                    {/*    </Navbar.Brand>*/}
                    {/*</h1>*/}
                    <div className="wrapforgot">
                        <Row>
                            <Col md="4" className="mx-auto text-center">
                                <div>
                                    <img  src={require('../../assets/img/datedish logo .png')}  />
                                </div>
                                <h6>Forgot Password</h6>
                                {errors?   <div className="error"> {errors?errors:''} </div>:''}
                                <div className="success"> {success}     </div>

                                <Form.Group>
                                    <Form.Control type="email" onChange={this.handleOnChange}  name="email" value={email} placeholder="Email Address"/>
                                    <span className="error_msg">{errors['email']}</span>
                                    {/* {emailErrorMsg ? <div className="error">{emailErrorMsg}</div> : null} */}
                                </Form.Group>

                                {!success? <Button type="submit" onClick={this.handleForgotSubmit} variant="dark">Submit</Button>:
                                    // <Link href='/' variant="dark">Go Back</Link>

                                    <Button onClick={this.goBack} variant="dark">Go Back</Button>}

                            </Col>
                        </Row>
                    </div>
                </Container>

            </div>


        )
    }


}


function mapStateToProps(state) {
    const {props, login, user} = state;
    return {
        props,
        login,
        user
    }
}

export default connect(mapStateToProps)(ForgotPassword);
